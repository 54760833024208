import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-referal-report',
  templateUrl: './referal-report.component.html',
  styleUrls: ['./referal-report.component.scss']
})
export class ReferalReportComponent implements OnInit {
  selected: any = false;
  userDetails: any = [];
  logsettlemnt: any = [];
  totalAmount: number = 0;
  loader: boolean = false;
  userList: any;
  total_statement: any;
  totalBalance_profit: any;
  user: any;
  totalBalance_profit_hold: any;
  logo: string;
  url: any;
  weblink:any;
  constructor(private socket: Socket, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getDetials();
    this.changeIcon();
  }

  async getStorage() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      this.user = data;
      // console.log(this.user)
      return data;
    } catch (e) {
      return null;
    }

  }

  async getDetials() {
    this.userDetails = await this.getStorage();
    this.getRefAcc();
  }

  clipBoard(val: any) {
    if (this.selected) {
      this.selected = false;
    }
    else {
      this.selected = true;
    }

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getRefAcc() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      sort: { time: -1 },
    };

    this.socket.emit('get-referal-userList', userdata);

    this.socket.on('get-referals-user-success', (function (data: any) {
      // console.log(data);
      this.total_statement = data.totalBalance;
      this.totalBalance_profit = data.totalBalance_profit;
      this.totalBalance_profit_hold = data.totalBalance_profit_hold;
      this.userList = data.userList;

    }).bind(this));

  }

  total_sellment() {
    if (confirm('Are you Sure Do Settlement')) {

      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        },
        sort: { time: -1 },
      };
      this.socket.emit('settlement-referal', userdata);

      this.socket.on('set-referals-transfer-success', (function (data: any) {
        alert(data.message)
      }).bind(this));
    }
  }

  async findHostName() {
    return (window.location.hostname);
  }

  async changeIcon() {
    const hostname = await this.findHostName();
    const splithostname = hostname.split('.')
    this.logo = splithostname[0];
    if (this.logo) {
      this.downloadApk();
    }
  }

  downloadApk() {
    if (this.logo === 'clubaob'){
      this.url = 'https://bit.ly/3uXxx38';
      this.weblink = 'https://paisaexch.com/';
    }
    else if (this.logo === 'clubprt') {
      this.url = 'https://bit.ly/3PlW8H7';
      this.weblink = 'https://clubprt.com/';
    }
    else if (this.logo === 'clubosg') {
      this.url = 'https://bit.ly/3B0AtzK';
      this.weblink = 'https://clubosg.com/';
    }
    else if (this.logo === 'ferrariclubb') {
      this.url = 'https://bit.ly/3aVxM8j';
      this.weblink = 'https://ferrariclubb.com/';
    }
    else if (this.logo === 'betx') {
      this.url = 'https://bit.ly/3OmN2Zf';
      this.weblink = 'https://paisaexch.com/';
    }
    else if (this.logo === 'boomboombook') {
      this.url = 'https://bit.ly/44gLmJo';
      this.weblink = 'http://boomboombook.com/';
    }
    else if (this.logo === 'dlexch') {
      this.url = 'https://bit.ly/3cCLnSe';
      this.weblink = 'https://dlexch.com/';
    }
    else if (this.logo === 'fairbets247') {
      this.url = 'https://bit.ly/3Ru5p0j';
      this.weblink = 'https://paisaexch.com/';
    }
    else if (this.logo === 'paisaexch' || this.logo === 'dubaiclub247') {
      this.url = 'https://bit.ly/3IY4Glj';
      this.weblink = 'https://paisaexch.com/';
    }
    else if (this.logo === 'clublucky7') {
      this.url = 'https://bit.ly/3IhBLJD';
      this.weblink = 'https://clublucky7.com/';
    }else if (this.logo === 'telegrambook') {
     // this.url = 'https://bit.ly/3IhBLJD';
      this.weblink = 'https://telegrambook.com/';
    }
    else{
      this.url = 'https://bit.ly/3IY4Glj';
      this.weblink = 'https://paisaexch.com/';
    }

  }

  sharePromoCode() {
    const promoCode = this.user?.details?.promoCode;
    const url = this.url;
    const web = this.weblink;
    const message = `Hey Friend, look what i have for you found the best sports and casino betting site and i am introducing the same to you Its called 
    ${web}. Signup now using my refer code ${promoCode} and you get 1500 welcome bonus in your bonus wallet. I got my first withdrawal in less than 2 minutes, why don’t you give it a try!`
    
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
    // this.chatService.sendMessage(`Use promo code: ${this.promoCode}`);
  }


}
