import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-referal-history',
  templateUrl: './referal-history.component.html',
  styleUrls: ['./referal-history.component.scss']
})
export class ReferalHistoryComponent implements OnInit {

  userDetails:any = [];
  logsettlemnt:any = [];
  totalAmount:number=0;
  loader:boolean=false;
  userList:any;
  user_id:any;
  ref_history:any;
  total_ref:any;
  total_statement:any;
  constructor(private route: ActivatedRoute,private socket: Socket,private toastr: ToastrService) {
    this.route.paramMap.subscribe(param=>{
      this.user_id=param.get('id') // (+)Converts string 'id' to number
      });
   }

  ngOnInit(): void {
    this.getDetials();
  }

  async getStorage(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async getDetials() {
    this.userDetails=await this.getStorage();
    this.getRefAcc();
  }

  getRefAcc() {
    const userdata = {
      _id:this.user_id,
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {

          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      sort: {time: -1},
    };
   
    this.socket.emit('get-referal-history', userdata);
    
    this.socket.on('get-referals-history-success',(function(data:any){
      console.log(data)
     this.ref_history=data.logList;
     this.total_ref=data.total;
     
      
     }).bind(this));
    
  }


 

}
