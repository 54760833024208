import { Component, OnInit, ViewChild, ChangeDetectorRef,TemplateRef, Inject, OnDestroy, AfterContentChecked } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fixed-deposit-report',
  templateUrl: './fixed-deposit-report.component.html',
  styleUrls: ['./fixed-deposit-report.component.scss']
})
export class FixedDepositReportComponent implements OnInit {
  userDetails:any;
  ledgerList:any;
  days:number=1;
  modalRef:any;
  referLog:any;
  total:any=0;
  constructor( private modalService: BsModalService,private socket: Socket,private toastr: ToastrService) { }
  
  ngOnInit(): void {
    this.storeData();
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async storeData()
  {
    this.userDetails=await this.getDetials();
    this.getLedgerSoc(2);
  }

    getLedgerSoc(value:number)
    {

      const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        username: this.userDetails.details.username,
        deleted: false,
        time: {
          $gte: new Date(new Date().getTime() - (value * 24 * 60 * 60 * 1000)),
        },
      },
      sort: {time: -1},
    };
  
    this.socket.emit('get-fixed-depsoit-report', userdata);

    this.socket.on('get-fixed-deposit-report-success', (function(data:any){
      if(data){ 
        console.warn(data);
        
        //this.toastr.success(data.message, 'Success!');
        this.ledgerList=data.data;
        this.total=data.total;
        this.removeListner();
      }
          
     }).bind(this));
    
  }

    days_diff(date_j,date_m)
  {
const date1:any = new Date(date_m);
const date2:any = new Date(date_j);
const diffTime = Math.abs(date2 - date1);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
return diffDays;
  }

  onValChange(value)
  {
    this.getLedgerSoc(value);
  }

  removeListner()
  {
     this.socket.removeListener('get-fixed-deposit-report-success');
  }

  refer_log(ledgerBook: TemplateRef<any>,x:any)
  {
    this.referLog=x?.schemeArr;
    this.modalRef = this.modalService.show(
      ledgerBook,
      Object.assign({}, { class: 'bets-modal modal-lg' })
    );
  }

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
