<!-- <mat-card fxLayout="row"> -->
        <h4>Fancy Position - {{selectedFancyMarket.name}}</h4>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div  fxLayout="column" fxLayoutAlign="center center" fxFlex="50%">
            <label >Runs</label>
            <button class="fancy-btn"  [ngClass]="{'red':(fancyPos.value)<0,'green':(fancyPos.value)>0}" *ngFor="let fancyPos of fancyPosData;let i=index;">{{fancyPos.key}}</button>
            
        </div>
        <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center center">
            <label>P&L</label>
            <button class="fancy-btn" color="primary"  [ngClass]="{'red':(fancyPos.value)<0,'green':(fancyPos.value)>0}" *ngFor="let fancyPos of fancyPosData;let i=index;" >{{fancyPos.value}}</button>
            
        </div>
    </div>
<!-- </mat-card> -->