<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="contianermobile">
            <div class="row setmainhead">
                <mat-toolbar class="mat-toolbar-row">
                    <div class="left-col">
                        <mat-icon (click)="backClicked()" class="back">keyboard_arrow_left</mat-icon>
                    </div>
                    <div class="col">
                        <h2 class="title">IDs</h2>
                    </div>
                    <div class="col text-right">
                        <mat-icon (click)="gonotification()">notifications</mat-icon>
                    </div>
                </mat-toolbar>
            </div>

            <div class="main-content idxpage">
                <mat-tab-group>
                    <mat-tab label="My ID">
                        <ng-container>
                            <div class="row setid" *ngFor="let mid of myid">
                                <div class="col-8">
                                    <div class="row align-items-center">
                                        <div class="col-3 text-center p-0">
                                            <img class="setmyimage"
                                                src="https://acepunt.kushubmedia.com/uploads/sites/{{mid?.sites?.image}}">
                                        </div>
                                        <div class="col-9">
                                            <h2 class="sethead">{{mid?.sites?.name}}</h2>
                                            <!-- <h2 class="sethead1">{{mid?.sites?.url}}</h2> -->
                                            <!-- <h2 class="sethead1">{{mid?.createdAt|date:'medium'}}</h2> -->
                                            <h2 class="sethead1">{{mid?.remarks}}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row justifyflex-end">
                                        <div class="col setbutton1" (click)="gowithdraw(mid ,'deposit')">D</div>
                                        <div class="col setbutton2" (click)="gowithdraw(mid, 'withdraw')">W</div>
                                        <div class="col setbutton3" (click)="myiddetails(myiddetaile,mid)">...</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-tab>
                    <mat-tab label="CREATE ID">
                        <ng-container>

                            <div class="row setpayment crateidsection">

                                <div class="col-12 setcolumn" *ngFor="let id of createid">
                                    <div class="setcoin" (click)="IdDetaile(iddetaile,id)">
                                        <div class="id-img"><img class="setidimage"
                                                src="https://acepunt.kushubmedia.com/uploads/sites/{{id?.image}}"></div>
                                        <div class="id-title">
                                            <h2 class="sethead">{{id?.name}}</h2>
                                            <h2 class="sethead1">{{id?.url}}</h2>
                                        </div>
                                        <div class="id-btn">
                                            <sapn class="setbutton" (click)="gocreateid(id)">Create Id</sapn>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- Id detaile -->


<ng-template #iddetaile>
    <div class="modal-header model_header bg-warning ">
        <h4 class="modal-title">Min Bet</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()">close</mat-icon>
        </a>
    </div>

    <div class="modal-body idxpopbody">

        <mat-list>
            <div>
                <h4 class="modal-head">Details</h4>
            </div>
            <div class="row">
                <div class="col-5">DemoId</div>
                <div class="col-7">{{IdDetail?.demoId}} &nbsp;&nbsp;
                    <span (click)="copyToClip(IdDetail?.demoId)" class="all"><mat-icon
                            class="setcopy">file_copy</mat-icon></span>
                </div>
            </div>
            <div class="row">
                <div class="col-5">Password</div>
                <div class="col-7">{{IdDetail?.demoPassword}}&nbsp;&nbsp;
                    <span (click)="copyToClip(IdDetail?.demoPassword)" class="all"><mat-icon
                            class="setcopy">file_copy</mat-icon></span>

                </div>
            </div>
            <div class="row">
                <div class="col-5">Cricket</div>
                <div class="col-7">{{IdDetail?.cricket}}</div>
            </div>
            <div class="row">
                <div class="col-5">Football</div>
                <div class="col-7">{{IdDetail?.football}}</div>
            </div>
            <div class="row">
                <div class="col-5">Tennis</div>
                <div class="col-7">{{IdDetail?.tennis}}</div>
            </div>
            <div class="row">
                <div class="col-5">Horse Racing</div>
                <div class="col-7">{{IdDetail?.horse_racing}}</div>
            </div>
            <div class="row">
                <div class="col-5">Cards</div>
                <div class="col-7">{{IdDetail?.cards}}</div>
            </div>
            <div class="row">
                <div class="col-5">Live Casino</div>
                <div class="col-7">{{IdDetail?.live_casino}}</div>
            </div>
        </mat-list>

    </div>
</ng-template>

<!-- my Id Details -->

<ng-template #myiddetaile>
    <div class="modal-header model_header bg-warning">
        <h4 class="modal-title">My Id Details</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()">close</mat-icon>
        </a>
    </div>

    <div class="modal-body idxpopbody">

        <div class="row setpaymentdetaile">
            <div class="col-2">
                <img class="setidimage"
                    src="https://acepunt.kushubmedia.com/uploads/sites/{{MyIdDetail?.sites?.image}}">
            </div>
            <div class="col-10 setmodalcolumn">
                <span>{{MyIdDetail?.sites?.name}}</span><br>
                <span class="name">{{MyIdDetail?.sites?.url}}</span>
            </div>

        </div>

        <!-- <div>
        <h4 class="title">{{MyIdDetail?.type}} To {{MyIdDetail?.to}} <span
            [ngClass]="MyIdDetail?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{MyIdDetail?.status}}</span></h4>
      </div> -->
        <mat-list>
            <div>
                <h4 class="modal-head">Details</h4>
            </div>
            <div class="row">
                <div class="col-5">Username</div>
                <div class="col-7">{{MyIdDetail?.username}}</div>
            </div>
            <div class="row">
                <div class="col-5">Password</div>
                <div class="col-7">{{MyIdDetail?.password}}</div>
            </div>
            <div>
                <h4 class="modal-head">Id Balance Details</h4>
            </div>
            <div class="row">
                <div class="col-5">Available Balance</div>
                <div class="col-7">{{MyIdDetail?.balance}}</div>
            </div>
            <div class="row">
                <div class="col-5">Exposure</div>
                <div class="col-7">{{MyIdDetail?.exposure}}</div>
                <!-- <div class="col-7">{{MyIdDetail?.updatedAt|date:'medium'}}</div> -->
            </div>
        </mat-list>

        <div>
            <h4 class="title">Transaction Details</h4>
        </div>

        <div *ngIf="MyIdDetails">
            <div class="row password" *ngFor="let tdata of MyIdDetails">
                <div class="col-2">
                    <div class="text-success">
                        <img src="https://acepunt.kushubmedia.com/uploads/sites/{{MyIdDetail?.sites?.image}}"
                            class="setback">

                    </div>
                </div>
                <div class="col-10 setidcolumn">
                    <b class="p-0">
                        <span Class="settext">{{tdata?.type}} <span> To {{tdata?.to}}</span></span>
                        <span
                            style="float:right;color: #323223;letter-spacing: 0px;font-size: 12px; font-weight: 400;">{{tdata?.amount}}</span></b>
                    <p class="setremark">
                        <span>{{tdata?.createdAt|date:'medium'}} <span style="float:right"
                                [ngClass]="tdata?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{tdata?.status}}</span></span><br>
                        <span>Remark : {{tdata?.remarks}}</span>
                    </p>
                </div>
            </div>
        </div>

    </div>
</ng-template>