import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Router,ActivatedRoute} from "@angular/router";
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import {SidenavService} from '../services/sidenav.service';

@Component({
  selector: 'app-join-fixed-deposit',
  templateUrl: './join-fixed-deposit.component.html',
  styleUrls: ['./join-fixed-deposit.component.scss']
})
export class JoinFixedDepositComponent implements OnInit {
  userDetails:any;
  ListData:any;
  _id:any;
  amount:any;
  dbExist:any;
  status:boolean;
  constructor(private route: ActivatedRoute,private router: Router,private sidenav: SidenavService,private toastr: ToastrService,private socket: Socket,private sidenavService: SidenavService,private modalService: BsModalService)
   {
    this.route.paramMap.subscribe(param=>{
      this._id=param.get('id') // (+)Converts string 'id' to number
      });
      this.status=false;
   }

   ngOnInit(): void {
    const data= JSON.parse(sessionStorage.getItem('userDetails'));
    if(data)
    {
      this.getFixedDeposit();
      this.getFixedDepositList();
    }
   
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      
      return data;
    } catch (e) {
      return null;
    }
    
  }

  days_diff(date_j)
  {
const date1:any = new Date();
const date2:any = new Date(date_j);
const diffTime = Math.abs(date2 - date1);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
return diffDays;
  }

  async getFixedDeposit() {
    this.userDetails=await this.getDetials();
      const userdata = {
        _id:this._id,
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          
          details: {
            username: this.userDetails.details.username,
            manager: this.userDetails.details.manager,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-fixed-deposit-details', userdata);
      
      this.socket.on('get-fixed-deposit-details-success',(function(data:any){
        console.log(data)
        if(data){
          this.ListData=data?.data;
        }
       }).bind(this));
      
    }

    async joinFd()
    {
     
      if(this.amount=="" || this.amount==null)
      {
        this.toastr.error('amount is required..!', '', {
          timeOut: 10000,
        });
      }
     else if(this.amount<0)
      {
        this.toastr.error('amount invalid..!', '', {
          timeOut: 10000,
        });
      }
      else
      {
        this.status = true;
        this.userDetails=await this.getDetials();
        const userdata = {
          _id:this._id,
          amount:this.amount,
          fixed_depsoit_id:this._id,
          user: {
            _id: this.userDetails._id,
            key: this.userDetails.key,
            token:this.userDetails.verifytoken,
            
            details: {
              username: this.userDetails.details.username,
              manager: this.userDetails.details.manager,
              role: this.userDetails.details.role,
              status: this.userDetails.details.status,
            },
          }
        };
        
        this.socket.emit('join-fixed-depsoit', userdata);
        
        this.socket.on('join-fixed-deposit-success',(function(data:any){
          console.log(data);
          
        if(data.success)
        {
          this.status = false;
          this.getFixedDeposit();
          this.getFixedDepositList();
          this.toastr.info(data.message, '', {
            timeOut: 10000,
          });
          this.router.navigateByUrl('/fixed-deposit');
        }
        else
        {
          this.status = false;
          this.toastr.error(data.message, '', {
            timeOut: 10000,
          });
        }
         }).bind(this));
      }
    }


    async getFixedDepositList()
    {
      this.userDetails=await this.getDetials();
      const userdata = {
        _id:this._id,
        fixed_depsoit_id:this._id,
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          
          details: {
            username: this.userDetails.details.username,
            manager: this.userDetails.details.manager,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-fixed-depsoit', userdata);
      
      this.socket.on('get-fixed-deposit-success',(function(data:any){
       
      if(data.success)
      {
        this.dbExist=data.data;
        
      }
      else
      {
        this.toastr.error(data.message, '', {
          timeOut: 10000,
        });
      }
       }).bind(this));
    }
}
