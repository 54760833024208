<mat-sidenav-container fullscreen casinowalletpage>
    <mat-sidenav-content>
        <app-header></app-header>
        <div class="main-content">
            <div class="container-fluid">
                <div class="row">
                    <!-- <div class="col-12">
                        <h4 class="text-center mt-4" style="color:#fff;">Offer Ledger</h4>
                    </div> -->

                    <ng-contaier class="col-12">
                        <div class="setpage" zooming="true" style="overflow-x:auto;overflow-y:auto;">
                            <table cellspacing="0">
                              <thead>
                                <tr>
                                  <th class="sticky-header">S/No.</th>
                                  <th class="sticky-header">Join Date</th>
                                  <th class="sticky-header">End Date</th>
                                  <th class="sticky-header">Percentage</th>
                                  <th class="sticky-header">Rolling</th>
                                  <th class="sticky-header">Remaning Rolling</th>
                                  <th class="sticky-header">Exposuer</th>
                                  <th class="sticky-header">Total Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let row of offerstatment ,let i=index;">
                                  <td class="ion-text-left sticky-column ">
                                    <ion-label>{{i+1}}</ion-label>
                                  </td>
                                  <td class="ion-text-left sticky-column ">
                                    <ion-label>{{row.createdAt|date:'medium'}}</ion-label>
                                  </td>
                                  <td class="ion-text-left sticky-column ">
                                    <ion-label>{{row.endDate|date:'medium'}}</ion-label>
                                  </td>
                                  <td>{{row.percentage}}%</td>
                                  <td>{{row.max_no}}x</td>
                                  <td>{{row.total_amount*row.max_no - row.expoure_bet}}</td>
                                  <td>{{row.expoure_bet}}</td>
                                  <td>{{row.total_amount}}</td>
                                </tr>
                              </tbody>
                            </table>
                    
                            <div *ngIf="offerstatment?.length==0" class="nodata">
                              <ion-row>
                                <ion-col class="ion-text-center">
                                  <ion-label color="primary">
                                    <ion-icon name="sad-outline"></ion-icon>No Data found.
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                            </div>
                          </div>
                    </ng-contaier>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>