<mat-sidenav-container fullscreen>
  <mat-sidenav-content>

    <app-header></app-header>
    <div class="main-content container mt-6">

      <div class="row fixed-deposit">


        <mat-card>
          <mat-card-header>
            <mat-card-title>Thanks , you are giving interest our fixed deposit</mat-card-title>
            <mat-card-subtitle>Name: {{ListData?.name}}</mat-card-subtitle>
            <mat-card-subtitle>Period: {{ListData?.period}}</mat-card-subtitle>
            <mat-card-subtitle>Percentage: {{ListData?.percentage}} %</mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions>
            <mat-form-field class="example-full-width">
              <mat-label>Amount</mat-label>
              <input type="number" matInput [(ngModel)]="amount" placeholder="Enter Amount" value="">
            </mat-form-field>
            <button mat-button class="button-sub">
              <button *ngIf="status==false" (click)="joinFd()" mat-button color="primary">Join</button>
              <div *ngIf="status==true" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </mat-card-actions>

          <mat-card-actions *ngFor="let l of dbExist">
            <mat-card-subtitle>Amount: {{l?.amount}}</mat-card-subtitle>
            <mat-card-subtitle>Join Date: {{l?.createdAt|date:'medium'}}</mat-card-subtitle>
            <mat-card-subtitle>{{days_diff(l?.createdAt)}} Days Ago</mat-card-subtitle>
          </mat-card-actions>
        </mat-card>
        <br>



        <!-- end -->
      </div>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>