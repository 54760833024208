import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { UsersService } from '../services/users.service';
import bankName from '../data/bankName.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wallet-withdraw-detail',
  templateUrl: './wallet-withdraw-detail.component.html',
  styleUrls: ['./wallet-withdraw-detail.component.scss']
})
export class WalletWithdrawDetailComponent implements OnInit {
  show: any;
  banktype: any;
  withdrawmethod: any;
  addwithdraw: any;
  bankName: any;
  withdrawlId: any;
  token: any;
  bankid: any;
  otpButtonDisable: any;
  BankForm: FormGroup;
  upiForm: FormGroup;
  deletebankform: FormGroup;
  modalRef: BsModalRef;
  constructor(
    private _location: Location,
    private toastr: ToastrService,
    private usersService: UsersService,
    private modalService: BsModalService,
    private fb: FormBuilder,
  ) {
    this.banktype = 'bank';
    this.otpButtonDisable = false;


  }

  ngOnInit(): void {
    this.token = sessionStorage.getItem("token")
    this.getwithdral();
    this.createFrom();
    this.createupiform();
    this.deleteform();
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }

  getwithdral() {
    this.usersService.getdata("getwithdrawnMethod/" + this.token).subscribe((response: any) => {
      this.withdrawmethod = response.data;
    })
  }

  addbank(bankadd: TemplateRef<any>, detailes) {
    this.banktype = detailes.name;
    this.withdrawlId = detailes._id;
    this.bankName = bankName;
    this.modalRef = this.modalService.show(
      bankadd,
      Object.assign({}, { class: 'bankform modal-lg' })
    );
  }

  formdetaile(){
    this.createFrom();
    this.createupiform();
  }

  createupiform() {
    this.upiForm = this.fb.group({
      upino: ['', [Validators.required]],
      name: ['', [Validators.required]],
      otp: ['', [Validators.required]],
    })
  }
  
  deleteform() {
    this.deletebankform = this.fb.group({
      otp: ['', [Validators.required]],
    })
  }

  createFrom() {
    this.BankForm = this.fb.group({
      bankName: ['', [Validators.required]],
      ifsc: ['', [Validators.required]],
      accnumber: ['', [Validators.required]],
      name: ['', [Validators.required]],
      otp: ['', [Validators.required]],
    })
  }

  Addbank() {
    if (!this.BankForm.value.bankName) {
      return;
    } else if (!this.BankForm.value.accnumber) {
      return;
    } else if (!this.BankForm.value.ifsc) {
      return;
    } else if (!this.BankForm.value.name) {
      return;
    } else if (!this.banktype) {
      return;
    } else if (!this.BankForm.value.otp) {
      // alert(1)
      this.otpButtonDisable = true;
      this.usersService.getdata('createOTP/' + this.token).subscribe((result: any) => {
        console.log(result);
      })
      return;
    } else {
      // alert(2)
      var data = {
        accnumber: this.BankForm.value.accnumber,
        bankName: this.BankForm.value.bankName,
        ifsc: this.BankForm.value.ifsc,
        name: this.BankForm.value.name,
        type: 'bank',
        upi: "",
        withdrawlId: this.withdrawlId,
        otp : this.BankForm.value.otp,
      }
      // console.log(data);
      this.usersService.postdata('withdrawalMethod/' + this.token, data).subscribe((result: any) => {
        this.modalRef.hide();
        this.BankForm.reset();
        this.getwithdral();
        this.otpButtonDisable = false;
        if(result.success==true){
          this.toastr.success(result.message);
        }else{
          this.toastr.error(result.message);
        }
        
      }) // console.log(result);

      //   

    }
  }

  Addupi() {
    if (!this.upiForm.value.name) {
      return;
    } else if (!this.upiForm.value.upino) {
      return;
    } else if (!this.upiForm.value.otp) {
      // alert(1)
      this.otpButtonDisable = true;
      this.usersService.getdata('createOTP/' + this.token).subscribe((result: any) => {
        // console.log(result);
      })
      return;
    } else {
      var data = {
        accnumber: '',
        bankName: '',
        ifsc: '',
        name: this.upiForm.value.name,
        otp: this.upiForm.value.otp,
        // type: 'bank',
        upi: this.upiForm.value.upino,
        withdrawlId: this.withdrawlId,
      }
      // console.log(data);
      this.usersService.postdata('withdrawalMethod/' + this.token, data).subscribe((result: any) => {
        // console.log(result);
        this.getwithdral();
        // this.toastr.success(result.message);
        this.modalRef.hide();
        this.otpButtonDisable = false;
        this.upiForm.reset();
        if(result.success==true){
          this.toastr.success(result.message);
        }else{
          this.toastr.error(result.message);
        }
      })
    }
  }

  getotp(){
    this.otpButtonDisable = true;
    this.usersService.getdata('createOTP/' + this.token).subscribe((result: any) => {
      // console.log(result);
    })
  }

  openmodel(success: TemplateRef<any>, id) {
    // console.log(this.detaile);
    this.bankid = id;
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  opensubmitformmodel(submitform: TemplateRef<any>, id) {
    // console.log(this.detaile);
    this.bankid = id;
    this.modalRef = this.modalService.show(
      submitform,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  deletebank(bankid) {

    var formdata = { id: bankid ,otp:this.deletebankform.value.otp}
    this.usersService.putdata('deleteWithdrawlMethod/' + this.token, formdata).subscribe((result: any) => {
      // console.log(result);
      // this.toastr.success(result.message);
      this.getwithdral();
      this.modalRef.hide();
        this.otpButtonDisable = false;
        this.deletebankform.reset();
        if(result.success==true){
          this.toastr.success(result.message);
        }else{
          this.toastr.error(result.message);
        }
    })
  }

}
