import { AfterViewInit, Component,OnDestroy, OnInit,TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-player-battle-list',
  templateUrl: './player-battle-list.component.html',
  styleUrls: ['./player-battle-list.component.scss']
})
export class PlayerBattleListComponent implements OnInit,AfterViewInit {
  // @ViewChild('fantastyrule') templateRef: TemplateRef<any>;
  modalRef: BsModalRef;
  activeStatus:any='event';
  offer_data:any;
  bets:any;
  banner_img:any;
  marquetext:any;
  homeData: any;
  dataLength:number;
  cricketData: any;
  soccerData: any;
  tennisData: any;
  virtualCricketData:any;
  moment: any = moment;
  selectedSportData:any;
  betViewData:any;
  userDetails:any;
  targetElement: Element;
  tokenCheck:boolean=false;
  marketBattle:any;
  userDta:any;
  computerDta:any;
  computerPoint:any=0;
  userPoint:any=0;
  betData:boolean=false;
  tokenStatus:boolean=false
  paramToken:any;
  head_footer:boolean=true;
  constructor(private route: ActivatedRoute,private modalService: BsModalService,private router: Router,private toastr: ToastrService,private socket: Socket) 
  { 
    this.route.paramMap.subscribe(param=>{
      this.paramToken=param.get('token');
      if(this.paramToken)
      { 
        this.head_footer=false;
        localStorage.setItem('fantacy_model','true');
        sessionStorage.setItem('head_footer','false');
        this.login_with_id(this.paramToken);
      }
      else
      {
        this.get_player_evt();
      }
      
  });

    sessionStorage.setItem('betView',null);
  }

   ngOnInit() {
   
  }

  login_with_id(id:any)
  {
    const data={_id:id};
    
    this.socket.emit('login-unique',data);
        
        this.socket.on('login-unique-success',(function(data:any){
          if(data)
          {
            const output = data.output;
            sessionStorage.setItem('loginStatus','true');
            sessionStorage.setItem('userDetails',JSON.stringify(output));
            this.get_player_evt();
          }
         }).bind(this));

         this.socket.on('login-unique-error',(function(data:any){
          if(data)
          {
            this.toastr.error(data.message+' !');
          }
         }).bind(this));
  }


  get_player_evt()
  {
    this.userDetails=JSON.parse(sessionStorage.getItem('userDetails')); 
    this.socket.emit('get-player-event', {user:this.userDetails});
    this.socket.on('get-player-event-success', (function(data:any){
     this.marketBattle=data;
    }).bind(this));
  }

 back_rout(){
  window.history.back();
 }

  back()
  {
    sessionStorage.setItem('betView',null);
    this.betData=false;
  }

  async openBet(val:any)
  {
    await sessionStorage.setItem('betView',JSON.stringify(val));
    let data =  await sessionStorage.getItem('betView');
    this.betData=true;
    this.betViewData=JSON.parse(data);
    
    this.userDta=[];
    this.computerDta=[];
    let c;
    let u;
    this.computerPoint=0;
    this.userPoint=0;
    if(this.betViewData)
    {
      if(this.betViewData.selectionName=='User Select')
      {
        this.computerPoint+=parseInt(this.betViewData.runnerRuns);
      }
      if(this.betViewData.selectionName=='Computer Select')
      {
        this.userPoint+=parseInt(this.betViewData.runnerRuns);
      }
      for(var i=0;i<this.betViewData.runnerArray.length;i++)
      {
       
         if(this.betViewData.runnerArray[i].selected=='1')
         {

          this.userPoint+=parseInt(this.betViewData.runnerArray[i].runs);
          
            u={
              runnerName:this.betViewData.runnerArray[i].runnerName,
              runs:this.betViewData.runnerArray[i].runs,
            }
          this.userDta.push(u);
         }
         if(this.betViewData.runnerArray[i].selected=='2')
         {
          this.computerPoint+=parseInt(this.betViewData.runnerArray[i].runs);
        
          c={
            runnerName:this.betViewData.runnerArray[i].runnerName,
            runs:this.betViewData.runnerArray[i].runs,
          }
        this.computerDta.push(c);
          
         }
      }
    }
  
  }
  
  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  ngAfterViewInit() {
    // if(localStorage.getItem('fantacy_model')==='true'){
    //   const user = {
    //     id: 10
    //   };
    // this.modalRef = this.modalService.show(this.templateRef, {
    //   initialState : user
    // });

    // setTimeout(()=>{ localStorage.setItem('fantacy_model','false'); },1000);
    
    // }
    
  }


  openModalRules(fantastyrule: TemplateRef<any>) {
    if(this.tokenStatus){
      this.modalRef = this.modalService.show(
        fantastyrule,
        Object.assign({}, { class: 'fantastyrule-modal modal-lg',ignoreBackdropClick: true })
      );
    }else{
      this.router.navigate(['login']);
    }
    
  }

  async getMyBets(val:any) {
    this.userDetails=await this.getDetials();
    var getBet;
    if(val==1)
    {
       getBet = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        },
        filter: {
          eventTypeId: '3901',
          username: this.userDetails.details.username,
          deleted: false,
          result: 'ACTIVE',
        },
        sort: {placedTime: -1},
      };
      
    }
    else
    {
       getBet = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        },
        filter: {
          eventTypeId: '3901',
          username: this.userDetails.details.username,
          deleted: false,
          result: {$ne:'ACTIVE'},
        },
        sort: {placedTime: -1},
      };
      
    }
    
    this.socket.emit('get-bets', getBet);

    this.socket.on('get-bets-success', (function(data:any){ 
          this.bets=data;
         this.socket.removeAllListeners('get-bets-success');
      
     }).bind(this));
  
  }

 
  onImgError(event) { 
    event.target.src = 'assets/fantasty/default.png';
  }
  
  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
