<div>
    <div mat-dialog-title fxLayout="row">
        <h4 class="modal-title" fxFlex="90">Bet List of "{{selectedMarket}}"</h4>
        <a type="button" class="close"  fxFlex="10" (click)="closePopup()" style="color:#ffffff"><mat-icon>clear</mat-icon></a>
    </div>
    <div mat-dialog-content>
        <div class="body-inner">
            <div class="betFilter" fxLayout="row" fxLayoutAlign="space-around center">
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="allBetRadio" [checked]="true" value="all" [(ngModel)]="selectedPopAllBetFilter" (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="allBetRadio">All</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="backBet" value="1" [(ngModel)]="selectedPopAllBetFilter" (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="backBet">Back</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="betFilter" type="radio" id="layBet" value="0" [(ngModel)]="selectedPopAllBetFilter" (ngModelChange)="filterPopupallbetBasedRadioSelect($event,selectedPopAllBetFilter)">
                    <label class="form-check-label" for="layBet">Lay</label>
                </div> 
                
            </div>
            <form>
                <table class="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Selection</th>
                            <th>Rate</th>
                            <th>Stack</th>
                            <th>P|L</th>
                            <th>Type</th>
                            <th>Placed Time</th>
                        </tr>
                      
                    </thead>
                    <tbody>
                        <tr *ngFor="let bet of allPopBetData;let i = index;">
                            <td>{{i+1}}</td>
                            <td>{{(bet?.is_fancy == 1) ? (bet?.market_name) : (bet?.selection_name)}}</td>
                            <td>{{bet?.odds}}</td>
                            <td>{{bet?.stack}}</td>
                            <td>{{(bet?.p_l).toFixed(2)}}</td>
                            <td>{{bet?.is_back == 1 ? 'Back' : 'Lay'}}</td>
                            <td >{{bet?.createdAt | date: 'dd-mm-yyyy HH:mm'}}</td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>
</div>