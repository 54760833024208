import { Component, OnDestroy, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit,OnDestroy {
  userDetails:any;
  betList:any;

  constructor(private socket: Socket) { }

  ngOnInit(): void {
    this.getBetHistry();
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async getBetHistry()
  {
    this.userDetails=await this.getDetials();
    const userdata = {
      user: {
         _id: this.userDetails._id,
         key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
         username: this.userDetails.details.username,
         status:"MATCHED",
         deleted: false,
         result:{$ne:'ACTIVE'}, 
         placedTime: {$gte: (new Date((new Date()).getTime() - (30*24*60*60*1000)))}
      },
      sort: {placedTime: -1 },
    };
  
    this.socket.emit('get-bets', userdata);

    this.socket.on('get-bets-success', (function(data:any){
      if(data){ 
        this.betList=data;
      }
          
     }).bind(this));
    
  }

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }
    
  }
  
 
