<mat-sidenav-container fullscreen >
    <mat-sidenav-content>
        <div class="contianermobile">
            <div class="header">
                <mat-toolbar class="mat-toolbar-row">
                    <div class="left-col">
                        <mat-icon (click)="backClicked()" class="back">keyboard_arrow_left</mat-icon>
                    </div>
                    <div>
                        <span><img src="../../assets/logo/localhost.png" alt="" style="height: 54px;"></span>
                        <span class="title">Deposit</span>
                        <button id="openModalButton" [hidden]="true" (click)="openmodel(success)">test</button>
                    </div>
                
                </mat-toolbar>
            </div>


            <!-- <div class="row" style="margin:0px;">
            <div class="col-12"> -->

            <!-- deposit form -->


            <div *ngIf="type=='deposit'" class="deopsitformblock">
                <form class="example-form" [formGroup]='depositeAmountForm' (ngSubmit)='enteramount()'  *ngIf="!maxamounts">
                    <mat-form-field appearance="outline">
                        <mat-label>Deposit Amount</mat-label>
                        <input matInput placeholder="Coins" type="number" (keyup)="getamount($event)"  formControlName='amount' [ngModal]="maxamount">
                        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        <!-- <span *ngIf="depositeForm.controls['amount'].errors" class="red-alert-message title_msg"> -->
                        <mat-hint *ngIf="minAmount">Minimum Deposite Amount is {{minAmount}} Coins</mat-hint>
                        <!-- </span> -->
                    </mat-form-field>
                    <div class="submit-btn">
                        <button mat-raised-button><span class="setspan" >Enter
                                Coins</span></button>
                    </div>
                </form>
                <form class="example-form" [formGroup]='depositeForm' (ngSubmit)='depositamount()'  *ngIf="maxamounts">

                    <!--                     
                    <mat-form-field appearance="outline" *ngIf="!maxamounts">
                        <mat-label>Deposit Amount</mat-label>
                        <input matInput placeholder="Coins" type="number" [ngModal]="maxamount"
                            (keyup)="getamount($event)" formControlName='amount'>
                        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        <mat-hint *ngIf="minAmount">Minimum Deposite Amount is {{minAmount}} Coins</mat-hint>
                    </mat-form-field> -->


                    <div>

                        <!-- <div class="setlabel2">
                            <label>Deposit {{maxamounts}} Coins</label>
                        </div> -->

                        <div class="row setamount">
                            <div class="col-12 setlabel2">
                                <span>Deposit {{maxamounts}} Coins</span>
                            </div>

                        </div>

                        <div class="row setpayment">

                            <div class="col-12">
                                <label class="setlabel">*Please Select Payment Method</label>
                            </div>
                            <!-- <div class="col-12 sethedcol">
                        <div class="row imagrow"> -->
                            <div class="col-3 setbanktype" *ngFor="let method of depositmethod">
                                <div [ngClass]="banktype == 'bank' ? 'setimgset' : 'imgset'" (click)="addbank(method)">
                                    <img src="https://acepunt.kushubmedia.com/uploads/payments/{{method?.image}}">
                                </div>

                            </div>
                            <!-- </div>
                    </div> -->
                            <div class="col-12" *ngIf="banktype">
                                <div class="row setpanel" *ngIf="banktype?.paymenttype =='bank'">
                                    <div class="col-5 setname">
                                        Type
                                    </div>

                                    <div class="col-7 setdetaile">
                                        {{banktype?.paymenttype}}
                                    </div>
                                    <div class="col-5 setname">
                                        Name
                                    </div>
                                    <div class="col-7 setdetaile">
                                        {{banktype?.name}} <span (click)="copyToClip(banktype?.name)"><mat-icon
                                                class="setcopy">file_copy</mat-icon></span>
                                    </div>
                                    <div class="col-5 setname">
                                        Account No.
                                    </div>
                                    <div class="col-7 setdetaile">
                                        {{banktype?.accnumber}} <span
                                            (click)="copyToClip(banktype?.accnumber)"><mat-icon
                                                class="setcopy">file_copy</mat-icon></span>
                                    </div>

                                    <div class="col-5 setname">
                                        IFSC
                                    </div>
                                    <div class="col-7 setdetaile">
                                        {{banktype?.ifsc}} <span (click)="copyToClip(banktype?.ifsc)"><mat-icon
                                                class="setcopy">file_copy</mat-icon></span>
                                    </div>
                                </div>

                                <div class="row setpanel" *ngIf="banktype?.paymenttype =='barcode'">
                                    <div class="col-5 setname">
                                        Type
                                    </div>

                                    <div class="col-7 setdetaile">
                                        {{banktype?.paymenttype}}
                                    </div>
                                    <div class="col-5 setname">
                                        Name
                                    </div>
                                    <div class="col-7 setdetaile">
                                        {{banktype?.name}} <span (click)="copyToClip(banktype?.name)"><mat-icon
                                                class="setcopy">file_copy</mat-icon></span>
                                    </div>
                                    <!-- <div class="col-5 setname">
                                Account No.
                            </div>
                            <div class="col-7 setdetaile">
                                {{banktype?.accnumber}} <span (click)="copyToClip(banktype?.accnumber)"><mat-icon
                                        class="setcopy">file_copy</mat-icon></span>
                            </div>

                            <div class="col-5 setname">
                                IFSC
                            </div>
                            <div class="col-7 setdetaile">
                                {{banktype?.ifsc}} <span (click)="copyToClip(banktype?.ifsc)"><mat-icon
                                        class="setcopy">file_copy</mat-icon></span>
                            </div> -->
                                    <div class="col-12 text-center setimage">
                                        <img src="https://acepunt.kushubmedia.com/uploads/payments/{{banktype?.image}}"
                                            class="imageset">
                                    </div>
                                </div>

                                <div class="row setpanel" *ngIf="banktype.paymenttype=='upi'">
                                    <div class="col-5 setname">
                                        Type
                                    </div>

                                    <div class="col-7 setdetaile">
                                        {{banktype?.name}}
                                    </div>
                                    <div class="col-5 setname">
                                        Name
                                    </div>
                                    <div class="col-7 setdetaile">
                                        {{banktype?.upiName}} <span (click)="copyToClip(banktype?.upiName)"><mat-icon
                                                class="setcopy">file_copy</mat-icon></span>
                                    </div>
                                    <div class="col-5 setname">
                                        UPI
                                    </div>
                                    <div class="col-7 setdetaile">
                                        {{banktype?.upi}} <span (click)="copyToClip(banktype?.upi)"><mat-icon
                                                class="setcopy">file_copy</mat-icon></span>
                                    </div>

                                    <div class="col-5 setname">
                                        Moblie No
                                    </div>
                                    <div class="col-7 setdetaile">
                                        {{banktype?.mobile}} <span (click)="copyToClip(banktype?.mobile)"><mat-icon
                                                class="setcopy">file_copy</mat-icon></span>
                                    </div>
                                    <!-- <div class="col-12 text-center setimage">
                                <img src="https://acepunt.kushubmedia.com/uploads/payments/{{banktype?.image}}" class="imageset">
                            </div> -->
                                </div>
                            </div>

                        </div>

                        <!-- Offers -->
                        <!-- The slideshow -->

                        <div *ngIf="offers?.length!=0">
                            <label class="setlabel1">*Please Select The Bonus Of Your Liking</label>
                        </div>

                        <div id="demo" class="carousel slide my-2" data-ride="carousel" *ngIf="offers?.length!=0">
                            <div>
                                <ul class="couponRow">
                                    <li *ngFor="let offer of offers;let i = index"
                                        (click)="setbonus(offer , offerid = offer._id)"
                                        [ngClass]=" offerid == offer._id ? 'back' : 'lay'">
                                        <span><small>Bonus</small>
                                            {{offer.percentage}}%
                                        </span>

                                        <span> <small>Rolling</small>
                                            {{offer.multiple_value}}x
                                        </span>

                                        <span> <small>Max Bonus</small>
                                            {{offer.max_cap_value}}
                                        </span>
                                        <p><strong>Valid For {{offer.max_apply_days}} Days</strong></p>
                                        <!-- <p><strong>{{getdays(offer.endDate)}}</strong></p>
                                    <p><strong>{{(offer.endDate | date :  "MMM d, y h:mm:ss a")}}</strong></p> -->

                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="bonus" class="setpanelhead">
                                <p class="setp">
                                    <small>Terms And Conditions</small>
                                    <span class="div-spacer"></span>
                                    <mat-icon (click)="removeoffer()">highlight_off</mat-icon>
                                </p>
                                <p class="setpp"> <small>1. Offer valid For {{bonus?.max_apply_days}} days. </small></p>
                                <p class="setpp">
                                    <small *ngIf="bonus.max_apply != -1">2. Max Apply {{bonus.max_apply}} Time.</small>
                                    <small *ngIf="bonus.max_apply == -1">2. Max Apply Unlimited. </small>
                                </p>
                            </div>
                        </div>

                        <!-- payment screenshot -->

                        <div>
                            <label class="setlabel1">*Upload payment screenshot</label>
                            <input class="form-control setfile" type="file" formControlName='image'
                                (change)="onChange($event)">
                            <div class="text-center">
                                <img src="{{cardImageBase64}}" class="imageset" *ngIf="cardImageBase64">
                            </div>

                        </div>

                        <div class="submit-btn">
                            <button mat-raised-button [disabled]="loginButtonDisable"><span class="setspan">Deposite
                                    Coins</span></button>
                        </div>
                    </div>
                </form>

            </div>


            <!-- Id deposit form -->

            <div *ngIf="type!='deposit'" class="deopsitformblock">
                <div class="row setamount">
                    <div class="col-12 setcolumn">
                        <span>Deposit {{setamount}} Coins</span>
                    </div>

                </div>

                <form class="example-form" [formGroup]='depositeidForm' (ngSubmit)='depositidamount()'>

                    <div class="row setpayment">

                        <div class="col-12">
                            <label class="setlabel">*Please Select Payment Method</label>
                        </div>
                        <!-- <div class="col-12">
                            <div class="row imagrow"> -->
                        <div class="col-3 setbanktype" *ngFor="let method of depositmethod">
                            <div [ngClass]="banktype == 'bank' ? 'setimgset' : 'imgset'" (click)="addbank(method)">
                                <img src="https://acepunt.kushubmedia.com/uploads/payments/{{method?.image}}">
                            </div>

                        </div>
                        <!-- </div>
                        </div> -->
                        <div class="col-12" *ngIf="banktype">
                            <div class="row setpanel" *ngIf="banktype?.paymenttype =='bank'">
                                <div class="col-5 setname">
                                    Type
                                </div>

                                <div class="col-7 setdetaile">
                                    {{banktype?.paymenttype}}
                                </div>
                                <div class="col-5 setname">
                                    Name
                                </div>
                                <div class="col-7 setdetaile">
                                    {{banktype?.name}} <span (click)="copyToClip(banktype?.name)"><mat-icon
                                            class="setcopy">file_copy</mat-icon></span>
                                </div>
                                <div class="col-5 setname">
                                    Account No.
                                </div>
                                <div class="col-7 setdetaile">
                                    {{banktype?.accnumber}} <span (click)="copyToClip(banktype?.accnumber)"><mat-icon
                                            class="setcopy">file_copy</mat-icon></span>
                                </div>

                                <div class="col-5 setname">
                                    IFSC
                                </div>
                                <div class="col-7 setdetaile">
                                    {{banktype?.ifsc}} <span (click)="copyToClip(banktype?.ifsc)"><mat-icon
                                            class="setcopy">file_copy</mat-icon></span>
                                </div>
                            </div>

                            <div class="row setpanel" *ngIf="banktype?.paymenttype =='barcode'">
                                <div class="col-5 setname">
                                    Type
                                </div>

                                <div class="col-7 setdetaile">
                                    {{banktype?.paymenttype}}
                                </div>
                                <div class="col-5 setname">
                                    Name
                                </div>
                                <div class="col-7 setdetaile">
                                    {{banktype?.name}} <span (click)="copyToClip(banktype?.name)"><mat-icon
                                            class="setcopy">file_copy</mat-icon></span>
                                </div>
                                <div class="col-12 text-center setimage">
                                    <img src="https://acepunt.kushubmedia.com/uploads/payments/{{banktype?.image}}"
                                        class="imageset">
                                </div>
                            </div>

                            <div class="row setpanel" *ngIf="banktype.paymenttype=='upi'">
                                <div class="col-5 setname">
                                    Type
                                </div>

                                <div class="col-7 setdetaile">
                                    {{banktype?.name}}
                                </div>
                                <div class="col-5 setname">
                                    Name
                                </div>
                                <div class="col-7 setdetaile">
                                    {{banktype?.upiName}} <span (click)="copyToClip(banktype?.upiName)"><mat-icon
                                            class="setcopy">file_copy</mat-icon></span>
                                </div>
                                <div class="col-5 setname">
                                    UPI
                                </div>
                                <div class="col-7 setdetaile">
                                    {{banktype?.upi}} <span (click)="copyToClip(banktype?.upi)"><mat-icon
                                            class="setcopy">file_copy</mat-icon></span>
                                </div>

                                <div class="col-5 setname">
                                    UPI Name
                                </div>
                                <div class="col-7 setdetaile">
                                    {{banktype?.upiName}} <span (click)="copyToClip(banktype?.upiName)"><mat-icon
                                            class="setcopy">file_copy</mat-icon></span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- payment screenshot -->

                    <div>
                        <label class="setlabel1">*Upload payment screenshot</label>
                        <input class="form-control setfile" type="file" formControlName='image'
                            (change)="onChange($event)">
                        <div class="text-center setimage">
                            <img src="{{cardImageBase64}}" class="imageset" *ngIf="cardImageBase64">
                        </div>

                    </div>

                    <div class="submit-btn">
                        <button mat-raised-button [disabled]="loginButtonDisable"><span class="setspan">Deposite
                                Coins</span></button>
                    </div>
                </form>

            </div>
            <!-- </div>
        </div> -->
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>


<!-- Success Payment -->

<ng-template #success>
    <div class="modal-body">
        <mat-list class="setmat">
            <div class="text-right"><a type="button" class="close" data-dismiss="modal">
                    <!-- <mat-icon (click)="modalRef.hide()">close</mat-icon> -->
                </a></div>
            <div class="text-center" *ngIf="depositdata.success == true">
                <!-- <mat-icon class="setback">done</mat-icon> -->
                <img src="assets/images/success.svg" />
                <h5 class="setamount">{{depositdata?.doc?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>
                <button class="backbutton" (click)="modalRef.hide()" [routerLink]="['/dashboard']">Back To Home</button>
            </div>
            <div class="text-center" *ngIf="depositdata.success != true">
                <mat-icon class="setback1">cancel</mat-icon>
                <h5 class="setamount1">{{depositdata?.doc?.amount | currency:"&#8377;"}}</h5>
                <h4 class="trantion">{{depositdata?.message}}</h4>
                <button class="backbutton" (click)="modalRef.hide()" [routerLink]="['/dashboard']">Back To Home</button>
            </div>

        </mat-list>

    </div>
</ng-template>