import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../services/users.service';
import casinoDb from '../casinodb.json';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
@Component({
  selector: 'app-casino-url',
  templateUrl: './casino-url.component.html',
  styleUrls: ['./casino-url.component.scss']
})
export class CasinoUrlComponent implements OnInit,OnDestroy {
  gameId:any;
  loader:boolean=false;
  userDetails:any;
  iframUrl:any;
  amountId:any;
  depWith:any;
  casinoBal:any=0;
  walletBalance:any=0;
  amount:string='';
  casino:any;

  constructor(private socket: Socket, private router: Router, public sanitizer :DomSanitizer,public _location: Location, public httpClient:UsersService,private toastr: ToastrService) {
    this.userDetails=JSON.parse(sessionStorage.getItem('userDetails'));
    this.casino=JSON.parse(localStorage.getItem('casinoDb'));
   }

  ngOnInit(): void {
    this.getUserBalance();
    this.get_qtech();
  }

  efresh(): void {
    this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate([decodeURI(this._location.path())]);
    });
    if(sessionStorage.getItem('loginStatus') === "true"){
      this.getUserBalance();
    }
  }
  get_qtech()
  {
    let tableId;
    const space:any = casinoDb.games.filter(x => x.GameID === this.casino.gameId);
    if(space.length>0)
    {
      tableId=space[0].TableID;
    }else
    {
      tableId='test';
    }
    // console.log(this.userDetails.details.username);
    // console.log(this.casino.gameId);
    // console.log(tableId);
    
    this.httpClient.Get('getCasinolinkapp/'+ this.userDetails.details.username + '/' +this.casino.gameId + '/' + tableId).subscribe((res:any) => {
    console.log(res);
    
      if(res.error){
         this.toastr.error(res.message, 'Error!');
      }
      else{
       let url= res.url;
        this.iframUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    });
    
  }

  getUserBalance() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-user', userdata);
      
      this.socket.on('get-user-success',(function(data:any){
        if(data){
          this.walletBalance = data.balance;
          this.getCasinoBal();
        }
       }).bind(this));
      
    }

    getCasinoBal() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      this.socket.emit('get-userbalance', userdata);
      
      this.socket.on('get-balance-success',(function(data:any){
        
        if(data){
          this.casinoBal=data.amount;
        }
       }).bind(this));
      
    }

  action(id,value)
  {
    this.amountId=id;
    this.depWith=value;
  }
  
  onSubmit()
 { 
   if(this.amount=="")
   {
    this.toastr.error('amount is invalid', 'Error !');
   }
   else
   {
      this.loader=true;
      if(this.amountId=='1')
      {
       this.depositApi(this.amount);
      }
      else if(this.amountId=='2')
      {
       this.withdrawApi(this.amount);
      }
    
   }
 }

 withdrawApi(amount:string){
  this.httpClient.Get('casino-balance-withdrawapp/'+this.userDetails.details._id+ '/' +amount).subscribe((response:any) => {

    if(response.error){
      this.toastr.error(response.message, 'Error!');
      this.loader=false;
    }
    else
    {
     this.toastr.success(response.message, 'Success!');
     this.loader=false;
     this.refreshUsrBal();
     this.getCasinoBal();  
    }
  });

}

depositApi(amount:string)
{
  this.httpClient.Get('casino-balance-transferapp/'+ this.userDetails.details._id + '/' +amount).subscribe((response:any) => {

    if(response.error){
       this.toastr.error(response.message, 'Error!');
       this.loader=false;
    }
    else{
     this.toastr.success(response.message, 'Success!');
     this.loader=false;
     this.refreshUsrBal();
     this.getCasinoBal();
    }
  });
 
}

refreshUsrBal() {
  const userdata = {
    user: {
       _id: this.userDetails._id,
       key: this.userDetails.key,
       token:this.userDetails.verifytoken,
      details: {
        username: this.userDetails.details.username,
        role: this.userDetails.details.role,
        status: this.userDetails.details.status,
      },
    }
  };
  this.socket.emit('get-user', userdata);
  
  this.socket.on('get-user-success',(function(data:any){
    if(data){
      this.walletBalance = data.balance;
      this.httpClient.updateUserBalanceSubject(data);
    }
   }).bind(this));
  
}
backClicked() {
  // console.log('test')
  this._location.back();
 // this.router.navigate(['wallet']);
}
  ngOnDestroy() {
    localStorage.removeItem('casinoDb');
    this.socket.removeAllListeners();
  }

}
