<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header *ngIf="head_footer"></app-header>
        <div [ngClass]="{'iframe_head_spance': !head_footer,'main-content': head_footer}">
            <div *ngIf="marquetext" class="marquee-text">
                <mat-icon> rss_feed</mat-icon>
                <marquee width="100%" direction="left">{{marquetext}}</marquee>
            </div>
            
        
 <!-- casinoImg -->
 <ng-container *ngIf="tokenCheck">
  <div class="multi-card">
    <div class="scroll-card" style="padding: 14px;">
      <div class="itemsContain">
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/85246d801fef4d43a325637243661631_neon-thumbnails-18.png"
              class="card-img-top" alt="..." >
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/912af3fca2144874b7b7ab2de03cf63a_neon-thumbnails-13.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
           
        </div>
        <div class="d-flex card-group" style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/bce4691827854b14a631ba020001e847_neon-thumbnails-03.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/7343dec5ab5b4a36a6f926874f216389_neon-thumbnails-02.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
  
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/bce4691827854b14a631ba020001e847_neon-thumbnails-03.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/7343dec5ab5b4a36a6f926874f216389_neon-thumbnails-02.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/0730f21c63544456a0fa1eb250ae4992_neon-thumbnails-17.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/e910c519f92c41a58a9c59dc09e11250_neon-thumbnails-14.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/da0706276b4444d491846159bb1bf07a_neon-thumbnails-09.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/1afe8e32eb0d43a496d56979bf5e8b60_neon-thumbnails-10.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/89685a59c2dc46429b805875c37066a6_neon-thumbnails-16.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/8e0efab978a14ed483a794f9bb71ff96_neon-thumbnails-19.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/d67c61187ab248a58aefdeceff2edd96_neon-thumbnails-06.png"
              class="card-img-top" alt="...">
              <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/f42e05b9dcee4275b3e2addc2f73c606_neon-thumbnails-08.png"
              class="card-img-top" alt="...">
              <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
  
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/c33323b773474acf9f3c43b33e31ae91_neon-thumbnails-12.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/6fbb02d51cf044ea856eaa77faa77cd6_neon-thumbnails-15.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/23bce1903ca24717bfa7e6958f026aee_neon-thumbnails-07.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/2b26c9bd6797460dae6697ee093b0825_neon-thumbnails-05.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img src="https://images.supernowa.net/Document/GameImages/2594257625a948feaf82aee8447a8cfc_23-RNG-queen.jpg"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/198dec4fe9634a0493825705892cbd1f_neon-thumbnails-11.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
         
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/c88fc9c1bfc34e97b8e96a80007e34cf_neon-thumbnails-01.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
        </div>
  
      </div>
                          
    </div>
  
  </div>
  </ng-container>
 <!-- casinoImgEnd -->
    <div class="palyer-contestpage">

      <div *ngIf="lodTimer" id="overlay" class="laodintime" style="height:100%;display:flex;align-items:center;justify-content:center;">
        <div style="font-size:7rem;" class="font-weight-bold text-warning">{{lodTimer}}</div>
    </div>
    
 <div class="card-container">
  <div _ngcontent-qwe-c6="" class="event-box ng-tns-c6-0 ng-trigger ng-trigger-fadeInOut ng-star-inserted"><button _ngcontent-qwe-c6="" class="back-button" (click)="back_rout();"><i _ngcontent-qwe-c6="" class="fa fa-arrow-left" aria-hidden="true"></i></button></div>
    <div class="card-box" style="padding-top:5px;">
      
      <div class="events-main">
       
        <div class="evetns-tab">
          
          <ul>
            <li>
              <button class="tab-button " (click)="tabVlaue=1;cancelMarket()"  [ngClass]="tabVlaue == '1' ? 'active' : ''" type="button" fdprocessedid="ibtgkp">Contests <i aria-hidden="true" class="fa fa-star"></i>
              </button>
            </li>
            <li>
              <button class="tab-button" (click)="tabVlaue=2;getMyBets(1)" [ngClass]="tabVlaue == '2' ? 'active' : ''" type="button" fdprocessedid="8z0fxo">Joined Contest <i aria-hidden="true" class="fa fa-exchange"></i>
              </button>
            </li>
            <li>
              <button class="tab-button" (click)="tabVlaue=3;getMyBets(2)" [ngClass]="tabVlaue == '3' ? 'active' : ''" type="button" fdprocessedid="ybwvw">Settled Contest <i aria-hidden="true" class="fa fa-database"></i>
              </button>
            </li>
          </ul>
        </div>
         <div class="match-listing-wrapper" *ngIf="tabVlaue==1">  
            <ul  class="match-listing" [ngStyle]="head_footer== true ? {'height': 'calc(100vh - 230px)'} : {'height': 'calc(100vh - 130px)'}" *ngIf="marketData==null" >    
              <li class="match_box " *ngFor="let m of markets" (click)="moveBet(m)">
                <div class="image_wrap">
                  <img alt="match-image" class="img-fluid" height="500px" loading="lazy" width="281" src="{{m?.image}}" (error) ="onImgError($event)">
                  <div class="image-caption">
                    
                    <div class="notification-box">
                      <ul>
                        <li class="marquee"><marquee> {{m?.remark}}</marquee></li>
                      </ul>
                    </div>
                    <p>
                      <span>{{m?.marketName}}</span>
                    </p>
                  </div>
                </div>
                <div class="red-box">
                  <div>
                    <h6>Entry : {{m?.entryPrice}}</h6>
                  </div>
                  <div>
                    <!-- <h6>Prize : {{m?.entryTotal-m?.totalProfit+m?.poolPrice}}</h6> -->
                    <h6>Prize : {{cal_pertange(m)}}</h6>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <h3 class="no_event ">No contest found</h3> -->

            <div _ngcontent-mvw-c28="" class="match-listing-wrapper single-card ng-star-inserted" *ngIf="marketData">
              <ul _ngcontent-mvw-c28="" class="match-listing" [ngStyle]="head_footer== true ? {'height': 'calc(100vh - 230px)'} : {'height': 'calc(100vh - 130px)'}">
                 <li _ngcontent-mvw-c28="" class="match_box">
                    <picture _ngcontent-mvw-c28=""><img _ngcontent-mvw-c28="" _ngcontent-btg-c6="" alt="match-image" class="img-fluid" height="120" loading="lazy" width="200" src="{{marketData?.image}}" (error) ="onImgError($event)"></picture>
                    <p _ngcontent-mvw-c28="" class="highest-score">{{marketData?.marketName}}</p>
                    <div _ngcontent-mvw-c28="" class="inner-card">
                       <ul _ngcontent-mvw-c28="" class="input-listing">
                          <!---->
                          <li _ngcontent-mvw-c28="" class="ng-star-inserted" *ngFor="let r of marketData?.marketBook?.runners" >
                            <input _ngcontent-mvw-c28="" class="radioStyle"  [(ngModel)]="optionValue" name="optionValue" type="radio" value="{{r.selectionId}}" (click)="getValue(r)">
                            <label _ngcontent-mvw-c28="" class="prctage" for="417">
                              <span _ngcontent-mvw-c28="" style="color:white">{{r?.runnerName}}</span>
                              <progress _ngcontent-mvw-c28="" id="percentage" max="100" value="0"></progress>
                            </label>
                          </li>

                       </ul>
                       <button _ngcontent-mvw-c28="" class="btn cancel" style="background:black;color:white;margin-top:10px;" type="button" (click)="cancelMarket()">Cancel</button><!----><button _ngcontent-mvw-c28="" style="margin-top:10px;" class="btn submit ng-star-inserted" (click)="placeBet(marketData)" type="button">Submit</button><!---->
                       <div _ngcontent-mvw-c28="" class="notification-box ng-star-inserted">
                          <ul _ngcontent-mvw-c28="">
                             <li _ngcontent-mvw-c28="" class="marquee" *ngIf="marketData?.poolPrice">Prize = Pool + {{marketData?.poolPrice}}</li>
                          </ul>
                       </div>
                       <div _ngcontent-mvw-c28="" class="red-box">
                          <div _ngcontent-mvw-c28="">
                             <h6 _ngcontent-mvw-c28="" >Entry :  {{marketData?.entryPrice}}</h6>
                          </div>
                          <div _ngcontent-mvw-c28="">
                             <h6 _ngcontent-mvw-c28="" *ngIf="marketData?.entryCount==1">Prize: {{marketData?.entryPrice+marketData?.poolPrice}}</h6>
                             <h6 _ngcontent-mvw-c28="" *ngIf="marketData?.entryCount!=1">Prize: {{marketData?.entryTotal-marketData?.totalProfit+marketData?.poolPrice}}</h6>
                            </div>
                       </div>
                    </div>
                 </li>
              </ul>
           </div>


        </div>
        <div *ngIf="tabVlaue==2" class="match-listing-wrapper gusses_wrap contextTab ">
          
          <ul class="match-listing" [ngStyle]="head_footer== true ? {'height': 'calc(100vh - 230px)'} : {'height': 'calc(100vh - 130px)'}">
            
            <li class="match_box win " *ngFor="let b of bets">
              <div class="image_wrap">
                <img alt="match-image" class="img-fluid" height="461" loading="lazy" width="200" src="{{b?.image}}" (error) ="onImgError($event)">
                <div class="image-caption">
                  <p>{{b?.marketName}}</p>
                </div>
              </div>
              <h6></h6>
              <ul class="input-listing">
                
                <li class="" *ngFor="let r of b?.marketBook?.runners">
                  <div class="chkboxdiv">
                    <div class="round">
                      <input class="radioStyle" disabled="" name="option" type="checkbox" [checked]="b?.runnerId==r?.selectionId" readonly>
                      <label for="checkbox"></label>
                    </div>
                  </div>
                  <label class="prctage">
                    <span class="eName">{{r?.runnerName}}</span>
                    
                    <span class="perValue ">{{r?.sortPriority-1}} <br>
                      <span class="vote">Voted</span>
                    </span>
                    <progress id="percentage" value="{{r?.sortPriority-1}}" max="10"></progress>
                  </label>
                </li>
               
              </ul>
              
              <div class="totalRow">
                <span>Total Participants : {{b?.entryCount}}</span>
              </div>
              <div class="red-box">
                <div>
                  <h6>Entry : {{b?.entryPrice}}</h6>
                </div>
                <div>
                 
                  <!-- <h6 >Prize : {{b?.entryTotal-b?.totalProfit+b?.poolPrice}}</h6> -->
                  <h6 >Prize : {{cal_pertange(b)}}</h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div *ngIf="tabVlaue==3" class="match-listing-wrapper gusses_wrap ">
          
          <ul class="match-listing" [ngStyle]="head_footer== true ? {'height': 'calc(100vh - 230px)'} : {'height': 'calc(100vh - 130px)'}">
            
            <li class="match_box win lost " *ngFor="let b of bets">
              <div class="image_wrap">
                <img alt="match-image" class="img-fluid" height="461" loading="lazy" width="200" src="{{b?.image}}" (error) ="onImgError($event)">
                <div class="image-caption">
                  <p>{{b?.marketName}}</p>
                </div>
              </div>
              <h6>Your Answer:</h6>
              <ul class="input-listing">
                <li>
                  <span class="text-center bg-yellow">{{b?.selectionName}}</span>
                </li>
              </ul>

              <ul class="input-listing">
                
                <li class="" *ngFor="let r of b?.marketBook?.runners">
                  <div class="chkboxdiv">
                    <div class="round">
                      <input class="" disabled="" class="radioStyle" name="option" type="checkbox"  [checked]="b?.winners==r?.selectionId" readonly>
                      <label for="checkbox"></label>
                    </div>
                  </div>
                  <label class="prctage" [ngClass]="b?.winners==r?.selectionId ? 'winner ' : ''">
                    <span class="eName" >{{r?.runnerName}}</span>
                    
                    <span class="perValue " >{{r?.sortPriority-1}} <br>
                      <span class="vote">Voted</span>
                    </span>
                    <progress id="percentage" value="{{r?.sortPriority-1}}" max="10"></progress>
                  </label>
                </li>
               
              </ul>
              
              <div class="totalRow">
                <span>Total Participants : {{b?.entryCount}}</span>
              </div>
              <div class="red-box">
                <div>
                  <h6>Entry : {{b?.entryPrice}}</h6>
                </div>
                <div>
                  <!-- <h6>Prize : {{b?.entryTotal-b?.totalProfit+b?.poolPrice}}</h6> -->
                  <h6>Prize : {{cal_pertange(b)}}</h6>
                </div>
              </div>
              
              
              <h4 class="win" style="color:green" *ngIf="b?.result=='WON'">You guessed Right! </h4>
              <h4 class="lost" *ngIf="b?.result=='LOST'">You guessed Wrong! </h4>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>  
       
        <app-footer *ngIf="head_footer"></app-footer>
    </mat-sidenav-content>
 </mat-sidenav-container>