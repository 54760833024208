import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpHeaderResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public Base_Url = environment['adminServerUrl'];
  public token = localStorage.getItem('accessToken');
  logInHeader = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0'
  });
  constructor( private http:HttpClient) { }

  onLoginSubmit(loginInfo) {
    localStorage.removeItem('user_name');
    localStorage.removeItem('password');
    localStorage.removeItem('RememberMe');
    if(loginInfo.isRememberMe){
      localStorage.setItem('user_name', loginInfo.user_name);
      localStorage.setItem('password', loginInfo.password);
      localStorage.setItem('RememberMe', JSON.stringify(loginInfo.isRememberMe));
    }
    let body = new HttpParams()
      .set('user_name', loginInfo.user_name)
      .set('password', loginInfo.password)
      .set('grant_type', loginInfo.grant_type);

    return this.http.post<any>(this.Base_Url + 'user/userLogin',body, { headers: this.logInHeader });
  }
  logoutUser() {
    
    let  reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + this.token
      });
        return this.http.get<any>(this.Base_Url + 'user/logout', { headers: reqHeader });
      }


      clearLocalStorage(){
        let exclude =["user_name", "password","RememberMe"]
        for (var i = 0; i < localStorage.length; i++){
          var key = localStorage.key(i);
    
          if (exclude.indexOf(key) === -1) {
              localStorage.removeItem(key);
          }
      }
      }
}
