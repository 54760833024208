import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { SidenavService } from '../services/sidenav.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { PasswordStrengthValidator } from "../password-strength.validators"
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit{
    @ViewChild('changePwd', { static: true }) public privacyPopup: TemplateRef<any>;
    modalRef: BsModalRef;
    userDetails: any;
    walletBalance: number = 0;
    exposure: number = 0;
    casinoBal: number;
    hide: boolean = true;
    moment: any = moment;
    chngePass: number;
    submitted = false;
    public showPassword: boolean;
    tokenStatus: boolean = false;
    avaialableEventType: any;
    rollingBalance: any;
    ListData: any;
    offerstatment:any;
    myForms: FormGroup;
    constructor(private router: Router, private sidenav: SidenavService, private toastr: ToastrService, private socket: Socket, private sidenavService: SidenavService, private modalService: BsModalService, private fb: FormBuilder) { }
  
    ngOnInit(): void {
      if (sessionStorage.getItem('loginStatus') === "true") {
        this.getUserBalance();
        this.getFixedDeposit();
        this.tokenStatus = true;
        this.createpassword();
      }
    }
  
    async getDetials() {
      try {
        const data = await JSON.parse(sessionStorage.getItem('userDetails'));
        return data;
      } catch (e) {
        return null;
      }
  
    }
  
    async getUserBalance() {
      this.userDetails = await this.getDetials();
      // console.log(this.userDetails.details.manager);   
      this.avaialableEventType = this.userDetails?.details?.availableEventTypes.indexOf("8080");
      //  console.log(this.avaialableEventType);   
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token: this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      const offerdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token: this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        },
        filter: {
          username:this.userDetails.details.username,
          visible:true} 
      };
      // console.log(offerdata);
      this.socket.emit('get-user', userdata);
      // this.socket.emit('offer-statement', offerdata);
  
      this.socket.on('get-user-success', (function (data: any) {
        if (data) {
          this.walletBalance = data.balance;
          this.rollingBalance = data;
          // console.log(data);
  
          this.exposure = data.exposure;
          this.getCasinoBal();
        }
      }).bind(this));
  
      // this.socket.on('offer-statement-success', (function (data: any) {
      //   if (data) {
      //     this.offerstatment = data.data;
      //     console.log(this.offerstatment);
      //   }
      // }).bind(this));
  
    }
  
    createpassword() {
      this.myForms = this.fb.group({
        password: [null, Validators.compose([
          Validators.required, Validators.minLength(8), PasswordStrengthValidator])]
      });
    }
  
    passwordchange() {
      //  console.log(this.myForms.value);
  
    }
  
    onSubmitChangePassword() {
      this.submitted = true;
  
      if (this.myForms.value.password) {
        const userdata = {
          user: {
            _id: this.userDetails._id,
            key: this.userDetails.key,
            details: {
              username: this.userDetails.details.username,
              role: this.userDetails.details.role,
              status: this.userDetails.details.status,
            },
  
          },
          password: this.myForms.value.password,
          targetUser: '',
        };
  
        this.socket.emit('update-password', userdata);
  
        this.socket.on('update-password-success', (function (data: any) {
  
          if (data) {
            this.submitted = false;
            this.modalRef.hide();
            this.toastr.success(data.message, '', {
              positionClass: 'toast-bottom-right',
              timeOut: 1000
            });
            setTimeout(() => { this.logoutUser(); }, 1000);
          }
        }).bind(this));
      }
      else {
        this.toastr.error('new password is req');
      }
  
    }
  
  
    getCasinoBal() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      this.socket.emit('get-userbalance', userdata);
  
      this.socket.on('get-balance-success', (function (data: any) {
  
        if (data) {
          this.casinoBal = data.amount;
          // console.log(data);
        }
      }).bind(this));
  
    }
  
    dashboard() {
      this.router.navigate(['dashboard'])
    }
  
    inplay() {
      this.router.navigate(['inplay'])
    }
  
    toggleRightSidenav() {
      this.sidenav.toggle();
    }
  
    openModalResetPwd(changePwd: TemplateRef<any>) {
      if (this.tokenStatus) {
        this.modalRef = this.modalService.show(
          changePwd,
          Object.assign({}, { class: 'changePwd-modal modal-lg', ignoreBackdropClick: true })
        );
      } else {
        this.router.navigate(['login']);
      }
  
    }
  
    set_fantacy_model() {
      localStorage.setItem('fantacy_model', 'true');
    }
  
    async getFixedDeposit() {
      this.userDetails = await this.getDetials();
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token: this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            manager: this.userDetails.details.manager,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      // console.log(userdata);
  
      this.socket.emit('get-fixed-deposit-list', userdata);
  
      this.socket.on('get-fixed-deposit-list-success', (function (data: any) {
        // console.log(data)
        if (data) {
          this.ListData = data?.data;
          // console.log(this.ListData);
  
        }
      }).bind(this));
  
    }
  
    logoutUser() {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['login']);
      window.location.reload();
      window.location.replace('login');
    }
  
}
