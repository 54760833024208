import { Component, OnInit , TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-wallet-create-idx',
  templateUrl: './wallet-create-idx.component.html',
  styleUrls: ['./wallet-create-idx.component.scss']
})
export class WalletCreateIdxComponent implements OnInit {
  createid:any;
  Idform: FormGroup;
  token:any;
  depositdata:any;
  modalRef: BsModalRef;
  constructor(private router: Router,private modalService: BsModalService, private toastr: ToastrService,private usersService: UsersService,private _location: Location, private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.token = sessionStorage.getItem('token');
    var data = sessionStorage.getItem('createid');
    this.createid = JSON.parse(data);
    // console.log(this.createid);
    this.createFrom();
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }
  createFrom() {
    this.Idform = this.fb.group({
      username: ['', [Validators.required]],
      coins: ['', [Validators.required, Validators.min(500)]],
      wallet: false,
    })
  }
  // createidform(){
  //   console.log(this.Idform)
  // }

  openmodel(success: TemplateRef<any>) {
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  createidform() {
    if (this.Idform.value.wallet == true) {
      if (!this.Idform.value.coins) {
        this.toastr.error("Please Enter Amount");
        return;
      } else if (!this.Idform.value.username) {
        this.toastr.error("Please Enter Username");
        return;
      } else if (this.Idform.value.coins < 500) {
        this.toastr.error("amount can't be less than 500");
        return;
      } else {
        var data = new FormData();
        data.append('type', this.createid.type);
        data.append('typeId', this.createid.typeId);
        data.append('amount', this.Idform.value.coins);
        data.append('sites', this.createid._id);
        data.append('username', this.Idform.value.username);
        data.append('paymentType', 'wallet');
        data.append('image', '');
        this.usersService.postdata("createMysites/" + this.token, data).subscribe((response: any) => {
          // console.log(response);
          this.depositdata = response;
        if (response) {
          document.getElementById("openModalButton").click();
          this.Idform?.reset();
          // this.toastr.success(response.message);
        }
        })
      }
    } else {
      if (!this.Idform.value.coins) {
        this.toastr.error("Please Enter Amount");
        return;
      } else if (!this.Idform.value.username) {
        this.toastr.error("Please Enter Username");
        return;
      } else if (this.Idform.value.coins < 500) {
        this.toastr.error("amount can't be less than 500");
        return;
      } else {
        var formdata = JSON.stringify(this.Idform.value);
        sessionStorage.setItem('depositamount', formdata)
        this.router.navigate(['wallet-deposit/' + 'iddeposit']);
      }
    }
  }

  // ngOnDestroy(){
  //   sessionStorage.removeItem('createid');
  // }
}
