<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header></app-header>
        <div class="main-content">
            <div *ngIf="marquetext" class="marquee-text">
                <mat-icon> rss_feed</mat-icon>
                <marquee width="100%" direction="left">{{marquetext}}</marquee>
            </div>
            
 <!-- casinoImg -->
 <ng-container *ngIf="tokenCheck">
  <div class="multi-card">
    <div class="scroll-card" style="padding: 14px;">
      <div class="itemsContain">
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/85246d801fef4d43a325637243661631_neon-thumbnails-18.png"
              class="card-img-top" alt="..." >
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/912af3fca2144874b7b7ab2de03cf63a_neon-thumbnails-13.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group" style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/bce4691827854b14a631ba020001e847_neon-thumbnails-03.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/7343dec5ab5b4a36a6f926874f216389_neon-thumbnails-02.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
  
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/bce4691827854b14a631ba020001e847_neon-thumbnails-03.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/7343dec5ab5b4a36a6f926874f216389_neon-thumbnails-02.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/0730f21c63544456a0fa1eb250ae4992_neon-thumbnails-17.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/e910c519f92c41a58a9c59dc09e11250_neon-thumbnails-14.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/da0706276b4444d491846159bb1bf07a_neon-thumbnails-09.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/1afe8e32eb0d43a496d56979bf5e8b60_neon-thumbnails-10.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/89685a59c2dc46429b805875c37066a6_neon-thumbnails-16.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/8e0efab978a14ed483a794f9bb71ff96_neon-thumbnails-19.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/d67c61187ab248a58aefdeceff2edd96_neon-thumbnails-06.png"
              class="card-img-top" alt="...">
              <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/f42e05b9dcee4275b3e2addc2f73c606_neon-thumbnails-08.png"
              class="card-img-top" alt="...">
              <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
  
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/c33323b773474acf9f3c43b33e31ae91_neon-thumbnails-12.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/6fbb02d51cf044ea856eaa77faa77cd6_neon-thumbnails-15.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/23bce1903ca24717bfa7e6958f026aee_neon-thumbnails-07.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/2b26c9bd6797460dae6697ee093b0825_neon-thumbnails-05.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
          
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img src="https://images.supernowa.net/Document/GameImages/2594257625a948feaf82aee8447a8cfc_23-RNG-queen.jpg"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/198dec4fe9634a0493825705892cbd1f_neon-thumbnails-11.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
         
        </div>
        <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
          <div class="card">
            <img
              src="https://images.supernowa.net/Document/GameImages/c88fc9c1bfc34e97b8e96a80007e34cf_neon-thumbnails-01.png"
              class="card-img-top" alt="...">
            <div class="button"><a href="#"> Play Now</a></div>
          </div>
  
        </div>
  
      </div>
                          
    </div>
  
  </div>
  </ng-container>
 <!-- casinoImgEnd -->
    
          <!-- offerDesign end-->
 
          <div _ngcontent-cib-c46="" class="landingpage bgColor games-page newGamea">
            <div _ngcontent-cib-c46="" class="livescro maindiv p-0">
               <div _ngcontent-cib-c46="" class="headerimg"><img _ngcontent-cib-c46="" alt="" src="assets/fantasty/fantasy_games_bannerYR.png" srcset="" width="100%"></div>
               <div class="daily_contest_sec">
               <div _ngcontent-cib-c46="" class="container maibody">
                  <!--player_battel-->
                  <div class="row">
                  
                    <div _ngcontent-cib-c46="" class="col-sm-6">
                      <div _ngcontent-cib-c46="" class="imagwitex" routerLink="/player-battle"><img _ngcontent-cib-c46="" class="fadeinweb" src="assets/fantasty/player_battleY.jpeg"></div>
                   </div> 

                   <!--player_contest  -->
                  <div _ngcontent-cib-c46="" class="col-sm-6 ng-star-inserted">
                     <div _ngcontent-cib-c46="" class="imagwitex"  routerLink="/player-contest"><img _ngcontent-cib-c46="" class="fadeinweb" src="assets/fantasty/daily_contestY.jpeg"></div>
                  </div>
                 <!-- spinner -->
                  <div _ngcontent-cib-c46="" class="col-sm-6 ng-star-inserted">
                     <div _ngcontent-cib-c46="" class="imagwitex"  routerLink="/wheel-spinner"><img _ngcontent-cib-c46="" class="fadeinweb" src="assets/fantasty/spin_winY.jpeg"></div>
                  </div>
                  
                </div>
               </div>
              </div>
            </div>
         </div>
          
        </div>
       
        <app-footer></app-footer>
    </mat-sidenav-content>
 </mat-sidenav-container>