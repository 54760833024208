<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-backheader></app-backheader>
        <div *ngIf="!res_msg" class="main-content mt-2">
          <div class="bottom-menu">
              <mat-list> 
            <mat-list-item (click)="casino_games('LIVECASINO');" [ngClass]="{'active': casino_type === 'LIVECASINO'}">  <span>LIVE CASINO</span></mat-list-item><span class="dash_line" style=" margin-top: 3px; color: #ffff;
            font-size: 1.5rem;font-weight: 100;">|</span>
            <mat-list-item (click)="casino_games('SLOT');" [ngClass]="{'active': casino_type === 'SLOT'}">  <span>SLOTS</span></mat-list-item><span class="dash_line" style=" margin-top: 3px; color: #ffff;
            font-size: 1.5rem;font-weight: 100;">|</span>
            <mat-list-item (click)="casino_games('TABLEGAME');" [ngClass]="{'active': casino_type === 'TABLEGAME'}"> <span>TABLE GAMES</span></mat-list-item> <span class="dash_line" style=" margin-top: 3px; color: #ffff;
            font-size: 1.5rem;font-weight: 100;">|</span>
            <mat-list-item (click)="casino_games('SHOOTING');" [ngClass]="{'active': casino_type === 'SHOOTING'}">  <span>SHOOTING GAMES</span></mat-list-item><span class="dash_line" style=" margin-top: 3px; color: #ffff;
            font-size: 1.5rem;font-weight: 100;">|</span>
            <mat-list-item (click)="casino_games('INSTANTWIN');" [ngClass]="{'active': casino_type === 'INSTANTWIN'}"> <span>INSTANT WIN </span> </mat-list-item>
            <span class="dash_line" style=" margin-top: 3px; color: #ffff;
            font-size: 1.5rem;font-weight: 100;">|</span>
            <mat-list-item (click)="casino_games('SCRATCHCARD');" [ngClass]="{'active': casino_type === 'SCRATCHCARD'}"> <span>SCRATCH CARD </span> </mat-list-item>
            <span class="dash_line" style=" margin-top: 3px; color: #ffff;
            font-size: 1.5rem;font-weight: 100;">|</span>
            <mat-list-item (click)="casino_games('VIRTUAL_SPORTS');" [ngClass]="{'active': casino_type === 'VIRTUAL_SPORTS'}"> <span>VIRTUAL SPORTS </span> </mat-list-item>
            <!-- <span class="dash_line" style=" margin-top: 3px; color: #ffff;
            font-size: 1.5rem;font-weight: 100;">|</span>
            <mat-list-item (click)="casino_games('AURA_CASINO');" [ngClass]="{'active': casino_type === 'AURA_CASINO'}"> <span>AURA CASINO </span> </mat-list-item> -->
         
              </mat-list>
          </div>
          
          <!-- casino_footer_start -->
          <div class="itemsContain casioncustom  slotsmallgames">
            <!-- live_casino -->
            <!-- <ng-container *ngIf="casino_type==='LIVECASINO'">
              <div class="card" (click)="openCasino('BTL-betonteenpatti')">
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/livecasinogaming.jpg"
                  class="card-img-top" alt="..." >
                <div class="button"><a>Pragmatic Play</a></div>
              </div>
              <div class="card" (click)="openCasino('EZU-cricketwar')">
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/euzgi_casino.png"
                  class="card-img-top" alt="..." >
                <div class="button"><a>Euzgi Casino</a></div>
              </div>
              <div class="card" (click)="openCasino('EVO-blackjack')">
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/livecasino/evolution_casino.png"
                  class="card-img-top" alt="..." >
                <div class="button"><a>Evolution Casino</a></div>
              </div>
            </ng-container> -->
            
 
        <ul  class="casinolistgrid">
            <ng-container *ngIf="casino_data?.length>0">
              <li *ngFor="let x of casino_data">
                <div class="imgframe">
                  <div class="usercount">
                    <mat-icon class="material-icons sizecasino">people</mat-icon> 9954
                            </div>
                <div *ngIf="x.images" class="card-imgnew" (click)="openCasino(x.id)">
                  <img  [src]="x.images[0].url"
                    class="imgsize" alt="...">
                  <!-- <div class="button"><a>{{x.name}}</a></div> -->
                </div>
                <div class="game-detail">
                   <!-- <p class="namecasino">
                  {{x.name}}
                </p>  -->
                <p class="game-name">
                  {{x.name}}
                </p> 
                <!-- <p class="game-name">
                  Hindi Lightning Roulette
                </p> 
                <p class="namecasino bottomi">
                  ₹100 - 25L
                </p> -->
              </div>
              </div>
              </li>       
            </ng-container>
          </ul>
        </div>
  
        <!-- casino_footer_end -->
        </div>
        <div *ngIf="res_msg">
          <h1 id="err_msg">{{res_msg}}</h1>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>