<mat-sidenav-container fullscreen class="casinowalletpage">
    <mat-sidenav-content>
        <app-header></app-header>
      	<div class="main-content">
           <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h4 class="text-center mt-4" style="color:#000;">Wallet</h4>
                    </div>
                
                  <!-- <div class="balanceShow col-12">
                    <h5 class="mt-2 text-center">Balance:&nbsp;<span>{{userDetails?.details?.balance}}</span></h5>
                  </div> -->

                <!--tab show -->
                  <ng-contaier class="col-12">
                     <mat-tab-group mat-align-tabs="center">
                     <mat-tab>
                      <ng-template mat-tab-label style="min-width: 300px !important;">
                        <mat-icon> insert_chart_outlined</mat-icon>
                          Balance
                      </ng-template>
                      <div class="content" style="margin: 15px 10px 10px 10px; border-radius: 5px; padding:20px; ">
                        <div class="row">
                          <div class="col-12">
                            <h4 style="font-weight:500;">Note: <span>10 coins =  <span>1</span> coins in casino wallet</span></h4>
                          </div>
                        </div>
                        <div class="row" style="width:100%;margin-top:20px;background: #eeeeee; padding: 4px;">
                          <div class="col-2">
                            <img src="assets/images/walletIcon/main.png" width="30" height="30" alt="">
                          </div>
                          <div class="col-7" style="padding-top:6px;color:#000;"><span >Main Balance</span></div>
                          <div class="col-3" style="padding-top:6px;color:#000;"><span>{{walletBalance| number : '0.0-2'}}</span></div>
                        </div>
                        <div class="row" style="width: 100%; margin-top: 20px;background: #eeeeee;padding: 4px;">
                          <div class="col-2">
                            <img src="assets/images/walletIcon/casino.png" width="35" height="34" alt="">
                          </div>
                          <div class="col-7" style="padding-top:10px;color:#000;"><span> Casino Balance </span></div>
                          <div class="col-3" style="padding-top:10px;color:#000;"><span>{{casinoBal | number : '0.0-2'}}</span></div>
                        </div>
                        <div class="row" style="margin-top: 20px;" >
                          <div class="col-6" style="text-align:center ;">
                            <button class="btn-outline-success border border-success rounded"  (click)="action(1,'Deposit');" mat-stroked-button>DEPOSIT</button>
                          </div>
                          <div class="col-6" style="text-align:center ;">
                            <button class="btn-outline-danger border border-danger rounded"  (click)="action(2,'Withdraw');" mat-stroked-button>WITHDRAW</button>
                          </div>
                        </div>
                       
                        <div *ngIf="depWith" class="row mt-4" >
                           <div class="col-12">
                                <p for="deposit_withdrow_label_id" >{{depWith}}</p>
                                <mat-form-field class="example-full-width" appearance="fill">
                                  <mat-label>Amount</mat-label>
                                  <input type="number" [(ngModel)]="amount" matInput>
                                </mat-form-field>
                                <button class="btn-outline-success border border-success rounded ml-3" [disabled]="loader" (click)="onSubmit();" mat-stroked-button>Submit</button>
                            </div>
                        </div>
              
                      </div>
                    </mat-tab>
              
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon>assignment</mat-icon>
                         History
                      </ng-template>
                      <div style="background-color:lightgrey;height:68vh;">
                        <iframe class="sun historofrmae" [src]="iframeUrl" *ngIf="iframeUrl" marginwidth="0" marginheight="0" frameborder="0" width="100%" height="100%;" scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
                      </div>
                    </mat-tab>
              
                  </mat-tab-group>
                </ng-contaier>
                </div>
          </div>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
