<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="contianermobile">
            <div class="row setmainhead">
                <mat-toolbar class="mat-toolbar-row">
                    <div class="left-col">
                        <mat-icon (click)="backClicked()" class="back">keyboard_arrow_left</mat-icon>
                    </div>
                    <div class="col">
                        <h2 class="title">Offers</h2>
                    </div>
                    <!-- <div class="col text-right">
                    <mat-icon (click)="gonotification()">Offers</mat-icon>
                </div> -->
                </mat-toolbar>
            </div>

            <!-- <div class="row offersection">
            <div class="col-12" *ngFor="let offer of offers">
                <div class="setocoin" (click)="deposit()">
                    <img *ngIf="offer.image" class="setoimg" src="http://142.93.40.145:3006/uploads/{{offer.image}}">
                    <h2 class="setoffer">{{offer.percentage}}% discount {{offer.description}} </h2>
                    <h2 class="sethead1">{{id?.url}}</h2>
                </div>
                <div class="setbutton">Create Id</div>
            </div>
        </div> -->

            <div class="row offersection1">
                <div class="col-12" *ngFor="let offer of offers">
                    <div class="offercol">
                        <!-- <img *ngIf="offer.image" class="setoimg1"
                            src="http://142.93.40.145:3006/uploads/{{offer.image}}"> -->
                        <h2 class="setoffer1">Get {{offer.percentage}}% Discount !</h2>
                        <h2 class="setoffer2">Offer Valid : {{offer.endDate | date:'d MMM yy h:mm a'}} </h2>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>