import { Component,OnDestroy, OnInit,TemplateRef, ViewChild} from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import {Router,ActivatedRoute} from "@angular/router";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { UsersService } from '../services/users.service';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-player-battle-market',
  templateUrl: './player-battle-market.component.html',
  styleUrls: ['./player-battle-market.component.scss']
})
export class PlayerBattleMarketComponent implements OnInit {
  // @ViewChild('fantastyrule', {static: true}) public privacyPopup: TemplateRef<any>;
  modalRef: BsModalRef;
  userDetails:any;
  marquetext:any;
  lodTimer:any=0;
  clsInterval:any;
  targetElement: Element;
  tokenCheck:boolean=false;
  screenWidth:any;
  marketId:any;
  selectedPlayer:any;
  betamount:any;
  userPlayer:any;
  computerPlayer:any;
  allPlayerArr:any;
   allPlayerArrLeft:any;
    allPlayerArrAll:any;
  firstAttemptPlayer:any;
  selectedUser:any;
  bonusRuns:any;
  totalUserPoint:any;
  totalComputerPoint:any;
  userPoint:any;
  user:any;
  computerpoint:any;
  runningStatus:boolean=false;
  marketBattle:any;
  firstSelected:any=0;
  amount:any=0;
  tokenStatus:boolean=false;
  head_footer:boolean=true;
  constructor(private modalService: BsModalService,private route: ActivatedRoute,private router: Router,public toastr: ToastrService,private socket: Socket,private usersService: UsersService) { 
    if(sessionStorage.getItem('head_footer')==='false')
    {
     this.head_footer=false;
    }
 
   this.screenWidth=screen.width;
   
   this.route.paramMap.subscribe(param=>{
    this.marketId=param.get('id') // (+)Converts string 'id' to number
    });
    this.selectedPlayer=0;
    this.userPlayer=[];
    this.computerPlayer=[];
    this.allPlayerArr=[];
    this.allPlayerArrLeft=[];
    this.allPlayerArrAll=[];
    this.betamount=0;
    this.totalUserPoint=0;
    this.totalComputerPoint=0;
    this.bonusRuns=0;
    this.userPoint=0;
    this.computerpoint=0;

  }


  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      this.user=data;
      return data;
    } catch (e) {
      return null;
    }
    
  }

 
  async ngOnInit() {
    if(sessionStorage.getItem('loginStatus') === "true"){
      
      this.tokenStatus=true;
    }
    else
    {
      this.router.navigate(['dashboard'])
    }
    this.userDetails=await this.getDetials();
    this.socket.emit('get-player-market', {user:this.userDetails,marketId:this.marketId});

    this.socket.on('get-player-market-success', (function(data:any)
    {
     this.marketBattle=data;
     if(this.marketBattle)
     {
      this.marketBattle.marketBook.runners.forEach((val,inx)=>
      {
       this.marketBattle.marketBook.runners[inx].selected=0;
       this.allPlayerArr.push(this.marketBattle.marketBook.runners[inx]);
       this.allPlayerArrLeft.push(this.marketBattle.marketBook.runners[inx]);
      });

     
     }
     
    }).bind(this));

    this.socket.on('place-bet-success',(function(output:any){
      this.toastr.success(output.message+" try another bet");
      this.clearBet();
  }).bind(this));

  this.socket.on('place-bet-error',(function(output:any){
    this.toastr.error(output.message);
}).bind(this));
  }

  back() {
    window.history.back();
  }

  startGame(id:any)
  {
    this.runningStatus=true;
  }

  slectPlayerUser(event,r)
  {
   
 
    if(this.userPlayer.length>3)
    {
          let self=this;
          this.marketBattle?.marketBook?.runners.forEach((val,index)=>{
            if(val.selected!=1 && val.selected!=2)
            self.marketBattle.marketBook.runners[index].selected=3;
          }
          )  
        
      return;
    }
      if(r.selected=='1' || r.selected=='2')
      {
         return;
      }
      if(this.userPlayer.length>3)
      {

        return;
      }
     this.totalUserPoint=0;
     this.totalComputerPoint=0; 
      if(this.allPlayerArrLeft.length==0)return;
  var index= this.allPlayerArrLeft.findIndex((element)=>{return r.selectionId==element.selectionId} );
 
  if(index!= -1)
  {
  
  this.allPlayerArrLeft[index].selected='1';
 this.selectedPlayer=this.selectedPlayer+1;
 this.userPlayer.push(this.allPlayerArrLeft[index]);
  this.allPlayerArrAll.push(this.allPlayerArrLeft[index]);
  this.allPlayerArrLeft.splice(index,1);

  if(this.computerPlayer.length>3 && this.userPlayer.length>3)
      {
        let self=this;
      this.marketBattle?.marketBook?.runners.forEach((val,index)=>{
        if(val.selected!=1 && val.selected!=2)
        self.marketBattle.marketBook.runners[index].selected=3;
      }
      )  
      }
  
    if(this.selectedPlayer==4)
           {

           this.player();
           }
  this.slectPlayerComputer();

  }
this.totalUserPoint=0;
     this.totalComputerPoint=0;
     this.bonusRuns=0;
     this.userPoint=0;
     this.computerpoint=0;
     if(this.selectedUser=='1' && this.marketBattle)
     {
       this.bonusRuns=parseInt(this.marketBattle.marketBook.userPoint);
       this.computerpoint=this.marketBattle.marketBook.userPoint;
     }
     else
     {
       this.bonusRuns=parseInt(this.marketBattle.marketBook.computerPoint);
        this.userPoint=this.marketBattle.marketBook.computerPoint;
     }
     this.allPlayerArrAll.forEach((val)=>{
                if(val.selected=='1'){this.totalUserPoint+=parseInt(val.runs)}
                if(val.selected=='2'){this.totalComputerPoint+=parseInt(val.runs)}
                 });
     
  }

  slectPlayerComputer()
  {
 
 
    if(this.computerPlayer.length>3)
    {
      let self=this;
      this.marketBattle?.marketBook?.runners.forEach((val,index)=>{
        if(val.selected!=1 && val.selected!=2)
        self.marketBattle.marketBook.runners[index].selected=3;
      }
      )  
      return;
    }
     if(this.allPlayerArrLeft.length==0)return;
     var max= this.allPlayerArrLeft.reduce((max,val)=>  { return parseInt(val.runs) > parseInt(max.runs) ? val:max});
     var maxnum=max.runs;
     
     var index= this.allPlayerArrLeft.findIndex((element)=>{return maxnum==element.runs} );
     if(index!= -1)
     {
     
       this.allPlayerArrLeft[index].selected='2';
       this.allPlayerArrAll.push(this.allPlayerArrLeft[index]);
       this.computerPlayer.push(this.allPlayerArrLeft[index]);
      this.allPlayerArrLeft.splice(index,1);

      if(this.computerPlayer.length>3 && this.userPlayer.length>3)
      {
        let self=this;
      this.marketBattle?.marketBook?.runners.forEach((val,index)=>{
        if(val.selected!=1 && val.selected!=2)
        self.marketBattle.marketBook.runners[index].selected=3;
      }
      )  
      }
     
      if(this.allPlayerArrLeft.length==1)
      {
          // this.allPlayerArrLeft[0].selected='1';
          // this.allPlayerArrAll.push(this.allPlayerArrLeft[0]);
          // this.allPlayerArrLeft.splice(0,1);
          // this.selectedPlayer=this.selectedPlayer+1;

           if(this.selectedPlayer==4)
           {

           this.player();
           }


      }
      this.totalUserPoint=0;
     this.totalComputerPoint=0;
     this.bonusRuns=0;
     this.userPoint=0;
     this.computerpoint=0;
     if(this.selectedUser=='1' && this.marketBattle)
     {
       this.bonusRuns=this.marketBattle.marketBook.userPoint;
       this.computerpoint=this.marketBattle.marketBook.userPoint;
     }
     else
     {
       this.bonusRuns=this.marketBattle.marketBook.computerPoint;
        this.userPoint=this.marketBattle.marketBook.computerPoint;
     }
  this.allPlayerArrAll.forEach((val)=>{
                if(val.selected=='1'){this.totalUserPoint+=parseInt(val.runs)}
                if(val.selected=='2'){this.totalComputerPoint+=parseInt(val.runs)}
                 });

     }
     
     
  }

  async clearBet()
  {
    this.userDetails=await this.getDetials();
    this.socket.emit('get-player-market', {user:this.userDetails,marketId:this.marketId});
    this.selectedPlayer=0;
    this.userPlayer=[];
    this.amount=0;
    this.computerPlayer=[];
    this.allPlayerArr=[];
    this.allPlayerArrLeft=[];
    this.allPlayerArrAll=[];
    this.betamount=0;
    this.totalUserPoint=0;
    this.totalComputerPoint=0;
    this.bonusRuns=0;
    this.userPoint=0;
    this.computerpoint=0;
  }
  public player() {

    //this.showClickedplayer = !this.showClickedplayer;
}

async placeBet()
{
  
  if(this.computerPlayer.length!=4 || this.userPlayer.length!=4){
    this.toastr.error('please select least player');
    return;
  };
   if(this.amount==0 || this.amount=='' || this.amount==null)
   {
    this.toastr.error('bet stake invalid');
   }
   else if(this.amount<4)
   {
    this.toastr.error('min stake 5');
   }
   else if(this.amount>2000)
   {
    this.toastr.error('max stake 2000');
   }
   else
   {
    let selectionName;
    if(this.firstSelected=='1')
    {
      selectionName='User Select';
    }
    else
    {
      selectionName='Computer Select';
    }
    var b = {
      runnerId:this.allPlayerArrAll,
      selectionName:selectionName,
      rate: 0,
      runnerRuns:this.bonusRuns,
      stake:this.amount,
      market:this.marketBattle,
      type:'Back',
      slug:this.marketBattle.slug
    };
    this.userDetails=await this.getDetials();
    this.lodTimer=4;
    console.log(b);
     
    this.socket.emit('create-playerbet', {user:this.userDetails, bet:b});
    
    this.setIntLod();
   }
}
openModalRules(fantastyrule: TemplateRef<any>) {
  if(this.tokenStatus){
    this.modalRef = this.modalService.show(
      fantastyrule,
      Object.assign({}, { class: 'fantastyrule-modal modal-lg',ignoreBackdropClick: true })
    );
  }else{
    this.router.navigate(['login']);
  }
  
}

setIntLod(){
  this.clsInterval=setInterval(()=> { this.decValue(); }, 1000);
}
onImgError(event) { 
  event.target.src = 'assets/fantasty/default.png';
}
async decValue(){
  this.lodTimer--;
  if(this.lodTimer==0){
    const data=await sessionStorage.getItem('loginStatus');
    if(data)
    {
      // this.router.navigate(['/player-battle/'+this.user?._id]);
      this.back();
    }
    else
    {
      this.router.navigate(['/player-battle']);
    }
    
    clearInterval(this.clsInterval);  
  }
}


  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
