<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <app-header></app-header>
    <div class="main-content mt-2 ">
      <div style="margin:8px;margin-bottom: 125px;">
      <div class="bgcont">
        <table>
          <tr>
            <th>
              <span class="chpwd font ">{{ userDetails?.details?.username }}</span>
            </th>
            <th style="text-align: right;">
              <button class="chpwd" (click)="openModalResetPwd(changePwd)"><small>Change Password</small></button>
            </th>
          </tr>
        </table>

        <div class="user-bal-info">
          <div class="card-wrapper">
            <div class="bal-cont">
              <div class="avl-bal show-bal">
                <mat-label>Balance</mat-label>
                <p>{{walletBalance| number : '0.0-2'}}</p>
              </div>
              <div class="exp-bal show-bal">
                <mat-label>Exposure</mat-label>
                <p>{{exposure| number : '0.0-2'}}</p>
              </div>
            </div>
            <div class="d-w-btn">
              <button class="deposit" [routerLink]="['/wallet-deposit/deposit']"> Deposit</button>
              <button class="withdraw" [routerLink]="['/wallet-withdraw/withdraw']"> Withdraw</button>
            </div>
          </div>
        </div>       

      </div>

      <div class="report-menu">
        <!-- <h4>Reports</h4> -->
        <ul class="menu-list">
          <!-- <li>
            <a class="dropdown-item" [routerLink]=""><mat-icon
                class="material-icons iconsize">add_ic_call</mat-icon>
                 <span class="texturl">
                  Alernate Number
                 </span></a>
          </li>

          <li>
            <a class="dropdown-item" [routerLink]=""><mat-icon
                class="material-icons iconsize">mail</mat-icon>
                 <span class="texturl">
                  Add Email
                 </span></a>
          </li> -->
          <li>
            <a class="dropdown-item" [routerLink]="['/profile']"><mat-icon
                class="material-icons iconsize">person</mat-icon>
                 <span class="texturl">
                  Complete Profile
                 </span></a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/my-bet']"><mat-icon
                class="material-icons iconsize">tour</mat-icon>
                 <span class="texturl">
                  Active Bets
                 </span></a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/ledger']"><mat-icon
                class="material-icons iconsize">contacts</mat-icon>
                 <span class="texturl">
                  Account Statement
                 </span></a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/terms-condition']"><mat-icon
                class="material-icons iconsize">admin_panel_settings</mat-icon>
                 <span class="texturl">
                  Rules
                 </span></a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/wallet-withdraw-details']"><mat-icon
                class="material-icons iconsize">account_balance</mat-icon>
                 <span class="texturl">
                  Banking
                 </span></a>
          </li>
          <li  style="margin-bottom: 8px; margin:8px;font-size:10px; font-family: system-ui; line-height: 34px;">
           Join us Now <a href="javascript:void(0)"><img src="assets/images/osgbook/icon_dark_instagram.svg" style="margin: 8px;" ></a>   <a href="javascript:void(0)"> <img src="assets/images/osgbook/icon_dark_telegram.svg" style="margin: 8px;"></a>
          </li>
          <div class="logooutdiv">
          <div   class= "logdhight" (click)="logoutUser()">
            <a  class="dropdown-item borderlogout" >Logout</a>
          </div>
          </div>
        </ul>
      </div>
</div>
    </div>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #changePwd>
  <div matDialogTitle >

    <h4 class="modal-title">Change Password</h4>
<span> <i  (click)="modalRef.hide()" class="material-icons cnspo">cancel</i></span>
  </div>
  <form [formGroup]="myForms" (ngSubmit)='onSubmitChangePassword()' class="changepwdpopform">
    <mat-dialog-content>
      <div class="body-inner change-pwd">
        <div class="form-group myform">
          <label class="form-label">New Password*</label>
          <input [type]="showPassword ? 'text' : 'password'" name="chngePass" placeholder="Enter New Password" class="form-control" formControlName="password"
            required />
            <mat-icon  class="eye" matSuffix [class.hide]="showPassword" (click)="showPassword = !showPassword">visibility</mat-icon>
            <mat-icon  class="eye" matSuffix [class.hide]="!showPassword" (click)="showPassword = !showPassword">visibility_off</mat-icon>
        </div>
      </div>
      <div class="errors"
        *ngIf="myForms.get('password').invalid && (myForms.get('password').touched || myForms.get('password').dirty)">

        <div *ngIf="myForms.get('password').hasError('required')">
          password is required
        </div>
        <div *ngIf="myForms.get('password').errors.minlength">
          Password must be at least 8 characters
        </div>
        <div class="error-text" *ngIf="myForms.get('password').hasError('passwordStrength')">
          {{myForms.get('password').errors['passwordStrength']}}
        </div>
      </div>
      <!-- <div class="checkbox">
        <label>
          <input (change)="hide = !hide" type="checkbox" name="checkbox" /><span> show password</span>
        </label>
      </div> -->
    </mat-dialog-content>

    <mat-dialog-actions align="center">
      <div class="text-end">
        <!-- <a data-dismiss="modal" (click)="modalRef.hide()" mat-raised-button class="btn btn-danger">Cancel</a>
        &nbsp;&nbsp; -->
        <!-- <button (click)="onSubmitChangePassword()" mat-raised-button class="btn btn-success" [disabled]="submitted">
          Submit
        </button> -->
        <button mat-raised-button class="btn btn-success" [disabled]="!myForms.valid">Submit</button>
      </div>
    </mat-dialog-actions>
  </form>


</ng-template>