import { Component,OnDestroy, OnInit} from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import * as moment from 'moment';
import { Socket } from 'ngx-socket-io';


@Component({
  selector: 'app-fantasty-games',
  templateUrl: './fantasty-games.component.html',
  styleUrls: ['./fantasty-games.component.scss']
})
export class FantastyGamesComponent implements OnInit {

  activeStatus:any='event';
  offer_data:any;
  bets:any;
  banner_img:any;
  marquetext:any;
  homeData: any;
  dataLength:number;
  cricketData: any;
  soccerData: any;
  tennisData: any;
  virtualCricketData:any;
  moment: any = moment;
  selectedSportData:any;
  userDetails:any;
  targetElement: Element;
  tokenCheck:boolean=false;
  marketBattle:any;
  paramToken:any;
  constructor(private socket: Socket) { 
    
  }

  
  
  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

 

  async ngOnInit() {
    // this.userDetails=await this.getDetials();
    
  }

  
 
  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
