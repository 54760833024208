import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-casino-wallet',
  templateUrl: './casino-wallet.component.html',
  styleUrls: ['./casino-wallet.component.scss']
})
export class CasinoWalletComponent implements OnInit ,OnDestroy{
  loader:boolean=false;
  depWith:string;
  userDetails: any = [];
  casinoBal:number=0;
  amount:string='';
  amountId:string;
  iframeUrl:any;
  walletBalance:number;
  subscription: Subscription;
  constructor(private socket: Socket,public httpClient:UsersService,private toastr: ToastrService,private sanitizer:DomSanitizer) {
    this.httpClient.returnUserBalance().subscribe
      (data => { //message contains the data sent from service
        this.walletBalance = data.balance;
        // this.subscription.unsubscribe();
      });
   }

  ngOnInit(): void {
    this.getUsrBal();
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async getUsrBal() {
    this.userDetails=await this.getDetials();
    // this.walletBalance = this.userDetails.details.balance;
    this.refreshUsrBal();
    this.getCasinoBal();
    this.getWalletHist();
  }

  getCasinoBal() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    this.socket.emit('get-userbalance', userdata);
    
    this.socket.on('get-balance-success',(function(data:any){
      if(data){
        this.casinoBal=data.amount;
      }
     }).bind(this));
    
  }

  getWalletHist() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token:this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    this.socket.emit('get-history', userdata);
    
    this.socket.on('get-history-success',(function(data:any){
      if(data){
        this.iframeUrl=this.sanitizer.bypassSecurityTrustResourceUrl(data.url);;
      }
     }).bind(this));
    
  }

  refreshUsrBal() {
      const userdata = {
        user: {
           _id: this.userDetails._id,
           key: this.userDetails.key,
           token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      this.socket.emit('get-user', userdata);
      
      this.socket.on('get-user-success',(function(data:any){
        if(data){
          this.walletBalance = data.balance;
          this.httpClient.updateUserBalanceSubject(data);
        }
       }).bind(this));
      
    }

  action(id,value)
  {
    this.amountId=id;
    this.depWith=value;
  }

  onSubmit()
 { 
   if(this.amount=="")
   {
    this.toastr.error('amount is invalid', 'Error !');
   }
   else
   {
      this.loader=true;
      if(this.amountId=='1')
      {
       this.depositApi(this.amount);
      }
      else if(this.amountId=='2')
      {
       this.withdrawApi(this.amount);
      }
    
   }
 }

 withdrawApi(amount:string){
     this.httpClient.Get('casino-balance-withdrawapp/'+this.userDetails.details._id+ '/' +amount).subscribe((response:any) => {
 
       if(response.error){
         this.toastr.error(response.message, 'Error!');
         this.loader=false;
       }
       else
       {
        this.toastr.success(response.message, 'Success!');
        this.loader=false;
        this.refreshUsrBal();
        this.getCasinoBal();  
       }
     });

  }

  depositApi(amount:string)
  {
    console.log(amount);
     this.httpClient.Get('casino-balance-transferapp/'+ this.userDetails.details._id + '/' +amount).subscribe((response:any) => {
 console.log(response);
 
       if(response.error){
          this.toastr.error(response.message, 'Error!');
          this.loader=false;
       }
       else{
        this.toastr.success(response.message, 'Success!');
        this.loader=false;
        this.refreshUsrBal();
        this.getCasinoBal();
       }
     });
    
  }

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
