import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-offer-ledger',
  templateUrl: './offer-ledger.component.html',
  styleUrls: ['./offer-ledger.component.scss']
})
export class OfferLedgerComponent implements OnInit {
  userDetails:any;
  offerstatment:any;
  constructor( private socket: Socket) { }

  ngOnInit(): void {
    this.getoffer();
  }

  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }

  }

  async getoffer(){
    this.userDetails = await this.getDetials();
    const offerdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        username:this.userDetails.details.username,
        visible:true} 
    };
    // console.log(offerdata);
    this.socket.emit('offer-statement', offerdata);
    this.socket.on('offer-statement-success', (function (data: any) {
      if (data) {
        this.offerstatment = data.data;
        // console.log(this.offerstatment);
      }
    }).bind(this));
  }

}
