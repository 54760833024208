<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
      
        <app-header></app-header>
      	<div class="main-content container mt-6">
           
                      <div class="row fixed-deposit"> 
                        <mat-card class="example-card" *ngFor="let m of ListData" >
                           
                          <div class="fixed-deposit-content">
                            <div class="fixed-deposit-header">
                              <div class="fixed-deposit-title">
                            <h3>{{m?.name}}</h3>
                            <span>Time Period Month:{{m?.period}} || Interest: {{m?.percentage}}%</span>
                            <button class="btn btnprocess" routerLink="/join-fixed-deposit/{{m?._id}}">Proceed</button>
                              </div>
                              <div class="fixed-deposit-img">
                                <img mat-card-sm-image src="{{m?.logo}}" >
                              </div>
                            </div>
                            <div class="fixed-deposit-data">
                            <p><span style="color:red">Note: </span>
                                    if you procced your amount hold for {{m?.period}} month and you getting percentage amount daily basic anycase you withdraw amount then return 50% of amount.</p>
                            </div>
                          </div>                 
                         
                          </mat-card>
                         
                          
                <!-- end -->
              </div>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
