import { Component, OnInit , TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-wallet-notifications',
  templateUrl: './wallet-notifications.component.html',
  styleUrls: ['./wallet-notifications.component.scss']
})
export class WalletNotificationsComponent implements OnInit {
  token:any;
  notifications:any;
  detaile:any;
  modalRef: BsModalRef;
  constructor(private _location: Location,private router: Router,private modalService: BsModalService, private usersService: UsersService) { }

  ngOnInit(): void {
    this.token = sessionStorage.getItem('token');
    this.getNotification();
  }
  backClicked() {
    this._location.back();
  }
  getNotification() {
    this.usersService.getdata("getNotification/" + this.token).subscribe((response: any) => {
      // console.log(response);
      this.notifications = response.data;
    })
  }

  openmodal(notificationdetaile: TemplateRef<any>, detailes) {
    this.detaile = detailes;
    this.modalRef = this.modalService.show(
      notificationdetaile,
      Object.assign({}, { class: 'notificationdetaile modal-lg' })
    );
  }
}
