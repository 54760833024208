<mat-sidenav-container fullscreen class="pageledger">
    <mat-sidenav-content>
        <app-header></app-header>
        
      	<div class="main-content mt-3">
            <!-- button -->
            <p class="text-center font-weight-bold">
             Total Amount : {{total|number : '1.0-2'}}&nbsp;:&nbsp;&nbsp;&nbsp;   Days&nbsp;:&nbsp;&nbsp;&nbsp;
            <mat-button-toggle-group class="border border-primary rounded" name="fontStyle" aria-label="Font Style" [value]="days">
                <mat-button-toggle [value]=1 (change)="onValChange($event.value)">&nbsp;1&nbsp;</mat-button-toggle>
                <mat-button-toggle [value]=7 (change)="onValChange($event.value)">&nbsp;7&nbsp;</mat-button-toggle>
                <mat-button-toggle [value]=15 (change)="onValChange($event.value)">&nbsp;15&nbsp;</mat-button-toggle>
                <mat-button-toggle [value]=30 (change)="onValChange($event.value)">&nbsp;30&nbsp;</mat-button-toggle>
              </mat-button-toggle-group>
            </p>
            
           <!-- accordion -->
           <ng-container *ngFor="let x of ledgerList">
           <div [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'accGrnColor' : 'accRedColor'" class="mt-2 mx-2">

            <div class="card" style="background-color:#3D3D3D;">
              <div class="container-fluild">

              
              <div class="row">

                <div class="col-2 p-0">
                  <div class="card-body text-success">
                    <img  (click)="refer_log(ledgerBook,x)" src="assets/images/ledgerIcon/f_d.jpg" height="50px" width="100px" class="iconzo1">
                     </div>
                </div>
                <div class="col-10 p-0">
                    {{x?.amount|number : '1.0-2'}}
                 <!-- Depo/Withd... -->
                 <ng-container *ngIf="!x?.eventName">
                     <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit| number : '1.0-2'}}</p>
           
                 
                 </ng-container>

                   <!-- Cricket/Soccer... -->
                 <ng-container *ngIf="x?.eventName">
                  <p class="m-0">{{x?.eventName}}</p>
                  <p class="m-0">{{x?.marketName}}</p>
                  <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit: {{x.newLimit|number : '1.0-2'}}</p>
                 </ng-container>
                 <p class="m-0">{{x.createdAt|date:'dd-MM-YYYY'}}</p>
                 <p>Amount: {{x?.schemeArr?.amount|number : '1.0-2'}} , {{x?.schemeArr?.arr?.name}} , {{x?.schemeArr?.arr?.period}} ,Interest: {{x?.schemeArr?.arr?.percentage}}%  </p>
                <p>Days: {{days_diff(x?.fixed_deposit_join_date,x?.createdAt)}}</p>
                </div>
              </div>
            </div>
             
            </div>
              
            </div>

           </ng-container>
           <div style="margin-top:15rem;font-weight:500;" class="text-center" *ngIf="ledgerList?.length==0">There is no data to display</div>
        </div>

        <ng-template #ledgerBook>
          <div class="modal-header model_header bg-warning">
              <h4 class="modal-title">Fixed Deposit Scheme </h4>
              <a type="button" class="close" data-dismiss="modal">
                  <mat-icon (click)="modalRef.hide()">close</mat-icon>
              </a>
          </div>

      
          <div class="modal-body" style="height:230px">
              <div class="table-div modeltable">
                  <table class="table table-bordered">
                      <tr>
                
                          <th class="text-center text-warning" align="center">Name</th>
                          <th class="text-right text-warning" align="right">Period</th>
                          <th class="text-right text-warning" align="right">Percentage</th>
                      </tr>
                      <tr *ngIf="referLog">
                        <td class="text-right text-warning" align="right">{{referLog?.arr?.name}}</td>
                         
                          <td class="text-right text-warning" align="right">{{referLog?.arr?.period}}</td>
                          <td class="text-right text-warning" align="right">{{referLog?.arr?.percentage}}</td>
                      </tr>
                  </table>
                  <button style="float:right" class="bg-danger text-white" (click)="modalRef.hide()">Close</button>
              </div>
          </div>
      </ng-template>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>