import { Component,OnDestroy, OnInit} from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import {Router,ActivatedRoute} from "@angular/router";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { UsersService } from '../services/users.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-player-contest',
  templateUrl: './player-contest.component.html',
  styleUrls: ['./player-contest.component.scss']
})
export class PlayerContestComponent implements OnInit {
  clsInterval:any;
  lodTimer:any;
  activeStatus:any='event';
  offer_data:any;
  bets:any;
  runnerData:any;
  banner_img:any;
  marquetext:any;
  homeData: any;
  dataLength:number;
  optionValue:any;
  cricketData: any;
  markets:any;
  soccerData: any;
  tennisData: any;
  virtualCricketData:any;
  tabVlaue:any='1';
  marketData:any;
  moment: any = moment;
  selectedSportData:any;
  userDetails:any;
  targetElement: Element;
  tokenCheck:boolean=false;
  marketBattle:any;
  head_footer:boolean=true;
  paramToken:any;
  constructor(private route: ActivatedRoute,private router: Router,private toastr: ToastrService,private socket: Socket,private usersService: UsersService) { 
    
    this.marketData=null;
    this.lodTimer=0;
    this.route.paramMap.subscribe(param=>{
      this.paramToken=param.get('token');
      if(this.paramToken)
      { 
        this.head_footer=false;
        sessionStorage.setItem('head_footer','false');
        this.login_with_id(this.paramToken);
      }
      else
      {
        this.get_player_contest();
      }
      
  });

  }

  
  
  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

 

   ngOnInit() {
    
  }

  login_with_id(id:any)
  {
    const data={_id:id};
    
    this.socket.emit('login-unique',data);
        
        this.socket.on('login-unique-success',(function(data:any){
          if(data)
          {
            const output = data.output;
            sessionStorage.setItem('loginStatus','true');
            sessionStorage.setItem('userDetails',JSON.stringify(output));
            this.get_player_contest();
          }
         }).bind(this));

         this.socket.on('login-unique-error',(function(data:any){
          if(data)
          {
            this.toastr.error(data.message+' !');
          }
         }).bind(this));
  }

 async get_player_contest()
  {
    this.userDetails=await this.getDetials();
    this.socket.emit('get-player-contest', {user:this.userDetails,filter:{'eventTypeId':'3902',deleted:false,'marketBook.status':{$ne:'CLOSED'}}});
    this.socket.on('contest-market-success', (function(data:any){
      console.log(data)
      this.markets=data;
     }).bind(this));

     this.socket.on('place-bet-success',(function(output:any){
      this.toastr.success(output.message+" try another bet");
      this.socket.emit('get-player-contest', {user:this.userDetails,filter:{'eventTypeId':'3902',deleted:false,'marketBook.status':{$ne:'CLOSED'}}});
      this.clearBet();
  }).bind(this));

  this.socket.on('place-bet-error',(function(output:any){
    this.toastr.error(output.message);
    this.socket.emit('get-player-contest', {user:this.userDetails,filter:{'eventTypeId':'3902',deleted:false,'marketBook.status':{$ne:'CLOSED'}}});
   
}).bind(this));
  }

  async moveBet(m:any)
  {
   
    this.marketData=m;
  }

  cancelMarket()
  {
    this.marketData=null;
  }

  async getMyBets(val:any) {
    this.userDetails=await this.getDetials();
    var getBet;
    if(val==1)
    {
       getBet = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        },
        filter: {
          eventTypeId: '3902',
          username: this.userDetails.details.username,
          deleted: false,
          result: 'ACTIVE',
         
        },
        sort: {placedTime: -1},
      };
      
    }
    else
    {
       getBet = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        },
        filter: {
          eventTypeId: '3902',
          username: this.userDetails.details.username,
          deleted: false,
          result: {$ne:'ACTIVE'},
          
        },
        sort: {placedTime: -1},
      };
      
    }
    
    this.socket.emit('get-bets', getBet);

    this.socket.on('get-bets-success', (function(data:any){ 
          console.log(data)
          this.bets=data;
         this.socket.removeAllListeners('get-bets-success');
      
     }).bind(this));
  
  }

  
  onImgError(event) { 
    event.target.src = 'assets/fantasty/default.jpg';
  }

  getValue(val)
  {
    this.runnerData=val;
  }
  async placeBet(market:any)
  {
   
    if(!this.optionValue)
    {
      this.toastr.error('please select atleast one option');
    }
    else
    {
    
     var b = {
      runnerId:this.runnerData.selectionId,
      selectionName:this.runnerData.runnerName,
      rate: 0,
      stake:market.entryPrice,
      market:market,
      type:'Back',
  
    };
    this.userDetails=await this.getDetials();
    this.lodTimer=2;
  
    this.socket.emit('create-contestbet', {user:this.userDetails, bet:b});
    this.setIntLod();
    }
  }

  setIntLod(){
    this.clsInterval=setInterval(()=> { this.decValue(); }, 1000);
  }

  async decValue(){
    this.lodTimer--;
    if(this.lodTimer==0){
      this.router.navigate(['/player-contest']);
      
      clearInterval(this.clsInterval);  
    }
  }

  clearBet()
  {
    this.marketData=null;
  }

  back_rout(){
    window.history.back();
   }

   cal_pertange(b)
   {
    // console.warn('toatal_entry',b.entryCount);
    // console.warn('entryPrice',b.entryPrice);
    // console.warn('poolPrice',b.poolPrice);
    // console.warn('entryCommision',b.entryCommision);
    
    if(b.entryCommision==0)
    {
      // console.warn('entryCommision0',(b?.entryCount*b?.entryPrice)+b?.poolPrice);
       return ((b?.entryCount*b?.entryPrice)+b?.poolPrice);
    }
    else
    {
      var a=(b?.entryCount*b?.entryPrice)+b?.poolPrice;
      // console.warn('a',a);
      
      var b:any=(a/b.entryCommision);
      // console.warn('b',b);
      // console.warn('a-b',a-b);
      
      return (a-b);
    }
    
   }
  
  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
