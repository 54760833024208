<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-backheader></app-backheader>
        <div class="main-content termspage mt-2">
        <!-- content -->    
          <div>
            <div class="content-termsouter">
              <h4 style="color: #323232 !important;font-size:15px;">Rules</h4>
            <div class="content-terms">
          
              <mat-list class="sio">
                <span class="intro ">
                  <p>PLEASE READ OUR RULES BEFORE PLACING BET ** ONCE OUR EXCHANGE GIVE USERNAME AND PASSWORD, IT IS YOUR RESPONSIBILITY TO CHANGE THE PASSWORD.</p>
                  <p>1) CHEAT RULES: -</p>
                  <ul>
                    <li>T1.1) In Betfair & Fancy markets, If anyone is suspected using ground commentary or ground line or courtsiding(being in ground while betting), company will void all winning bets(without providing any proof). This is a zero tolerance policy and no arguments will be entertained. The bets will be voided after the match is completed. Company's decision will be final decision.</li>
                  </ul>


                  <p>2) MATCH ODDS RULES: -</p>
                  <p>T2.1) Cricket: We will follow Betfair result.</p>
                  <p>2.2) Soccer: We will follow Betfair result.</p>
                  <p> 2.3) Tennis: We will follow Betfair result.</p>
                  <p>T2.4) IF Any Client Found Hedging Or Doing Manipulation in Any Match Odds, then company has the right to Void the Bets.</p>
                  <p>2.5) If Exchange resettle any bets or Markets due to wrong Result Declaration or any other reason & if the Player has lost Money or If Player gets Negative Balance, In this case Company will do the Settlement from Agent for the same, all decision will be Full And Final, No Arguments will be listened.</p>
                 <p>
                  2.6) Company reserves the right to void any bets (only winning bets) of any event at any point of the match if the company believes there is any cheating/wrong doing in that particular event by the players (either batsman/bowler).
                 </p>
                  <p>3) BOOKMAKER MARKET RULES: -</p>
                  <p>3.1) Due to any reason any team will be getting advantage or disadvantage we are not concerned.</p>
                  <p>3.2) Wrong team selection by our mistake all back and lays bets will be deleted. </p>
                  <p>3.3) wrong rate bets also voided.</p>
                  <p>3.4) If Exchange resettle any bets or Markets due to wrong Result Declaration or any other reason & if the Player has lost Money or If Player gets Negative Balance, In this case Company will do the Settlement from Agent for the same, all decision will be Full And Final, No Arguments will be listened.</p>
                  <p>3.5) Company reserves the right to void any bets (only winning bets) of any event at any point of the match if the company believes there is any cheating/wrong doing in that particular event by the players (either batsman/bowler).</p>
                 <p>4) FANCY:-</p>
                 <p> 4.1) Advance fancy: - Advance Session, Player Runs and all Fancy Bets are only valid for 20 Over/40 over full match each side. (Please Note this condition is applied only in case of Advance Fancy Bets only).</p>
                <p>4.2) All advance fancy bets market will be suspended 60 mins prior to match and will be settled.</p>
                <p>4.3) Advance session and Lambi 1st inning valid only.</p>
                <p>4.4) Total match playing over adv: - We Will Settle this Market after Whole Match gets Completed.</p>
                <p>4.5) Criteria: - We Will Count Only Round- Off Over For Both the Innings While Settling (For Ex: - If 1st Batting team gets all out at 17.3, 18.4 or 19.5 we Will Count Such Overs as 17, 18 and 19 Respectively and if Match gets Ended at 17.2, 18.3 or 19.3 Overs then we will Count that as 17, 18 and 19 Over Respectively... and this Will Remain Same For Both the Innings.</p>
                 <p>4.6) In Case of Rain or if Over gets Reduced then this Market will get Voided.</p>
                 <p>4.7) 3 Wkt or more by bowler in match adv:- We Will Settle this Market after Whole Match gets Completed.</p>
                 <p>4.8) Once all session/fancy bets are completed and settled there will be no reversal even if the Match is Tied or is Abandoned.</p>
                 <p>4.9) Under the rules of Session/Fancy Bets if a market gets Suspended for any reason whatsoever and does not resume then all previous Bets will remain Valid and become HAAR/JEET bets.</p>
                 <p>4.10) Incomplete Session/Fancy Bet will be cancelled but Complete Session will be settled.</p>
                 <p>4.11) In the case of Running Match getting Cancelled/ No Result/ Abandoned but the session is complete it will still be settled. Player runs / fall of wicket/Wicket lost to ball/How many ball face by player will be also settled at the figures where match gets stopped due to rain for the inning (D/L) , cancelled , abandoned , no result.</p>
                 <p>4.12) If a player gets Retired Hurt and one ball is completed after you place your bets then all the betting till then is and will remain valid.</p>
                 <p>4.13) Should a Technical Glitch in Software occur, we will not be held responsible for any losses.</p>
                 <p>4.14) Should there be a power failure or a problem with the Internet connection at our end and session/fancy market does not get suspended then our decision on the outcome is final.</p>
                 <p>4.15) All decisions relating to settlement of wrong market being offered will be taken by management. Management will consider all actual facts and decision taken will be full in final.</p>
                 <p>4.16) Any bets which are deemed of being suspicious, including bets which have been placed from the stadium or from a source at the stadium maybe void at anytime. The decision of whether to void the particular bet in question or to void the entire market will remain at the discretion of Company. The final decision of whether bets are suspicious will be taken by Company and that decision will be full and final.</p>
                 <p>4.17) Any sort of cheating bet, any sort of Matching (Passing of funds), Court Siding (Ghaobaazi on commentary), Sharpening, Commission making is not allowed in Company, If any company User is caught in any of such act then all the funds belonging that account would be seized and confiscated. No argument or claim in that context would be entertained and the decision made by company management will stand as final authority.</p>
                 <p>4.18) If any case wrong rate has been given in fancy,that particular bets will be cancelled (Wrong Commentary).</p>
                 <p>4.19) In case customer make bets in wrong fancy we are not liable to delete, no changes will be made and bets will be considered as confirm bet.</p>
                 <p>4.20) any query regarding result or rate has to be contacted within 7 days from the event, query after 7 days from the event will not be considered as valid.</p>
                 <p>4.21) The Rules Which we have added Regarding Cheating/Hedging/manipulation of Odds are ONLY for the Ones with Wrong Intensions Not for Genuine Clients.</p>
                 <p>4.22) Penalty Runs - Any Penalty Runs Awarded in the Match (In Any Running Fancy or ADV Fancy) Will Not be Counted While Settling in our Exchange.</p>
                 <p>4.23) Player Boundaries Fancy:- We will only consider Direct Fours and Sixes hit by BAT.</p>
                 <p>4.24) Bowler run session rule:- If Bowler Bowl 1.1 over,then valid(For Bowler 2 over runs session) If Bowler Bowl 2.1 over,then valid(For Bowler 3 over runs session) If Bowler Bowl 3.1 over,then valid(For Bowler 4 over runs session) If Bowler Bowl 4.1 over,then valid(For Bowler 5 over runs session) If Bowler Bowl 9.1 over,then valid(For Bowler 10 over runs session)</p>
                 <p>4.25) Any cricket event which is being held behind closed doors in that if any players found to be taking advantage of groundline in fancy bets in such cases bets can be void after the market ends. Company decision to be final.</p>
                 <p>4.26) Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example,In case of VPN/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note: - only winning bets will be voided, For example: If we find such entries (above mentioned) from any id and their bets are (200000 lay in a 6 over session for the rate 40 and 200000 back for the rate of 48) and the actual score is 38, bets of 40 lay will be voided and the bets for 48 back will be considered valid.</p>
                 <p>4.27) Odd-even rules:- Incomplete games will be deleted. Over reduced or abandoned all bets will be deleted.</p>
                 <p>4.28) In any circumstances company decision will be final.</p>
                 <p>4.29) If Users Found Betting In Group's then We Will Void "Only The Winning Bets of the Users" and Company Decision Will be Full and Final.</p>
                 <p>4.30) If Exchange resettle any bets or Markets due to wrong Result Declaration or any other reason & if the Player has lost Money or If Player gets Negative Balance, In this case Company will do the Settlement from Agent for the same, all decision will be Full And Final, No Arguments will be listened.</p>
                 <p>4.31) Company reserves the right to void any bets (only winning bets) of any event at any point of the match if the company believes there is any cheating/wrong doing in that particular event by the players (either batsman/bowler)</p>
                 <p>4.32) Our Exchange Is Trusted and Fair.</p>
                 <p>5) IPL RULES:-</p>
                 <p>5.1) If Over Reduced in Match, we will not count the actual scores of the Over Reduced Matches instead we will count the Market’s Average Scores.</p>
                 <p>5.2) If Match is Abandoned, we will not count the actual scores of the Abandoned Matches instead we will count the Market’s Average Scores. NOTE:-These rules are for the following Markets of ENTIRE IPL 2021 (60 Matches):-</p>
                 <p>5.3) Total Fours: - Average 26 Fours will be given if the match is abandoned or over reduced.</p>
                 <p>5.4) Total Sixes: - Average 13 Sixes will be given if the match is abandoned or over reduced.</p>
                 <p>5.5) Total Wide: - Average 8 Wide’s will be given if the match is abandoned or over reduced.</p>
                 <p>5.6) Total Extras: - Average 14 Extras will be given if the match is abandoned or over reduced.</p>
                 <p>5.7) Total No Ball: - Average 1 No Ball will be given if the match is abandoned or over reduced.</p>
                 <p>5.8) Total Duck: - Average 1 Duck will be given if the match is abandoned or over reduced.</p>
                 <p>5.9) Total Fifties: - Average 2 Fifties will be given if the match is abandoned or over reduced.</p>
                 <p>5.10) Total Century: -Average 0 Century will be given if the match is abandoned or over reduced.</p>
                 <p>5.11) Total Run Out: - Average 1 Run Out will be given if the match is abandoned or over reduced.</p>
                 <p>5.12) Total Caught out: - Average 8 Caught Out will be given if the match is abandoned or over reduced.</p>
                 <p>5.13) Total Maiden Over: - Average 0 Maiden Over will be given if the match is abandoned or over reduced.</p>
                 <p>5.14) Total LBW: - Average 1 LBW will be given if the match is abandoned or over reduced.</p>
                 <p>5.15) Total Bowled: - Average 2 Bowled will be given if the match is abandoned or over reduced.</p>
                 <p>5.16) In case IPL Matches Gets Stopped or Interrupted Due to "COVID-19" or Any "ACT OF GOD" Reasons, under 45 Matches then All IPL 2021 Tournament Fancy Markets will be Voided, but if 45 or Above Matches are Being Played then we will Settle all the Fancy Markets and Also If there are NON-Played Matches Above 45 then we will settle the markets as per our Markets Average.</p>
                 <p>5.17) If IPL 2021 gets stopped due to “Covid-19” or Any “Act of God” reason then We will wait till 45 days from the day IPL gets stopped, and if IPL Matches gets Resumed before 45th day then we will continue as Usual Score Countation but if IPL 2021 does not resume until 45th day then we will settle the markets according to our above-mentioned Markets Averages and In case of Void, We will Void the under 45 matches on 45th day from the day IPL 2021 stopped.</p>
                 <p>6) Casino RULES:-</p>
                 <p>6.1) If Company voided any bet due to wrong rate or technical issue in casino games and the Player has lost Money or If Player gets Negative Balance, In this case Company will do the Settlement from Agent for the same, all decision will be Full And Final, No Arguments will be listened.</p>
                 <p>6.2) Exchange accept maximum bet as per below casino:</p>
                 <p>Evolution: 2,00,000 INR</p>
                 <p>Pragmatic: 2,00,000 INR</p>
                 <p>Bet Games: 2,00,000 INR</p>
                 <p>Vivo: 2,00,000 INR</p>
                 <p></p>
                </span>  
              </mat-list>
            </div>
            </div>
          </div>
        </div>
        <!-- <app-footer></app-footer> -->
    </mat-sidenav-content>
</mat-sidenav-container>