import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wallet-payment-method',
  templateUrl: './wallet-payment-method.component.html',
  styleUrls: ['./wallet-payment-method.component.scss']
})
export class WalletPaymentMethodComponent implements OnInit {
  depositeForm: FormGroup;
  loginButtonDisable = false;
  file: File = null;
  constructor(private _location: Location, private fb: FormBuilder,private router: Router) { }

  ngOnInit(): void {
    this.createFrom();
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }
  createFrom() {
    this.depositeForm = this.fb.group({
      image: ['', [Validators.required]],
    })
  }
  depositamount() {
    this.loginButtonDisable = true;
    // console.log(this.depositeForm.value);
    if (this.depositeForm.value.amount != '') {
      this.router.navigate(['wallet-payment-method']);
    }else{
      this.loginButtonDisable = false;
    }
  }

  // On file Select
  onChange(event) {
    this.file = event.target.files[0];
}
}
