<router-outlet></router-outlet>
<!-- <div *ngIf="displayLoadingIndigatior" class="spinner"></div> -->
<!-- <ngx-http-loader [loader]="loader.HOURGLASS" [iconColor]="'#ffc107'"></ngx-http-loader> -->

<button id="openModalButton" [hidden]="true" (click)="openDialog2(myModal)">Open Modal</button>
<ng-template #myModal>
    <div class="modal-body setbody">
        <mat-list class="setmat1">
            <!-- <div class="text-right"><a type="button" class="close1" data-dismiss="modal">
                    <mat-icon (click)="modalRefcancel.hide()">close</mat-icon>
                </a></div> -->
            <div class="text-right">
                <a type="button" class="close1" data-dismiss="modal">
                    <mat-icon (click)="modalRefcancel.hide()">close</mat-icon>
                </a>
               <img style="width:100%"  src="assets/images/offerpoint.png">
            </div>          
        </mat-list>
  
    </div>
  </ng-template>